.cart-icon {
    /* display: block; */
    position: relative;
    color: #fff;
    font-size: 25px;
}

.light .cart-icon{
    position: relative;
    font-size: 25px;
    color: #333;
}

.light .home .cart-icon{
    color: #fff;
}

/* .light .cart-icon:hover {
    color: #4276a5;
} */

.cart-icon .shopping-cart-length {
    position: absolute;
    font-size: 10px;
    top: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff!important;
    background: #eb3b3b;
    font-weight: bold;
    border-radius: 12px;
    border: 2px solid #fff;
    font-family: Arial;
    right: auto;
    padding: 4px 8px;
    margin-left: 10px;
    margin-right: 0;
}

.light .cart-icon .shopping-cart-length{
    background:#eb3b3b;
}

.topIconsLinks:hover .shopping-cart-length{
    color: #fff;
}

.topIconsLinks.active .shopping-cart-length{
    color: #fff;
}