.react-tabs {

    &__tab {
        background-color: $color-background;
        border-radius: $border-radius-base $border-radius-base 0 0;
        margin-right: $space-xxs;
        border: 1px solid $color-border;
        padding: $space-sm $space-lg;
        border-bottom: none;
        color: $color-text;
        font-weight: 500;
        outline: none;
        position: relative;
        bottom: auto;

        &:not(.react-tabs__tab--selected):hover {
            background-color: transparentize($color: $color-background, $amount: .25);
        }

        &--selected {
            border-color: $color-border;
            background-color: $color-background;
            border-top: 3px solid $color-border-active;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: $space-xxs;
                bottom: - ($space-xxs / 2);
                left: 0;
                background: $color-border-inverse;
                z-index: 20;
            }
        }
    }

    &__tab-list {
        margin: 0;
        border-bottom: none;
        @include flex(row, flex-end, flex-start);
    }

    &__tab-panel {
        border: 1px solid $color-border;
        background: $color-background;
        padding: $space-lg;
        border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
        position: relative;
        overflow-y: auto;
        height: auto;
        // 454px
    }
}