.leftbar {
    width: 80px;
    position: fixed;
    top: 65px;
    left: 0;
    background-color: $color-background;
    height: calc(100% - 65px);
    border-right: 1px solid $color-border;

    .leftnav {
        margin-top: $space-xxl;

        &__link {
            color: $color-primary;
            font-size: $font-size-xl;
            @include flex-inline(row, center, center);
            cursor: pointer;
            margin: $space-lg 0;
            width: 100%;

            &:hover {
                color: $color-secondary;

                .icon {
                    background-color: $color-icon-active;
                }
            }

            &--active {
                
                .icon {
                    background-color: $color-icon-active;
                }
            }
        }
    }
}