.search-pages-wrapper {
    padding: 0;
}

.search-types-wrapper {
    display: flex;
    justify-content: space-between;
}

.GrayOutImage {
    margin-top: 30px;
    background-color: #fff;
    text-align: center;
}

.GrayOutImage img{
    width: 100%;
    border: 0px;
    padding: 30px 0px;
}     

.awsLogoWrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.awsLogoWrapper img {
    width: 100px;
}

.no-result-wrapper {
    text-align: center;
}

/* Search Blocks Refactored */

.search-blocks {
    display: flex;
    justify-content: space-between;
}

.search-blocks .block {
    background: #fff;
    padding: 16px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.search-blocks .block.block-disabled::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .5);
    z-index: 10;
}

.search-blocks .block-reg,
.search-blocks .block-pn {
    width: 23%;
}

.search-blocks .block-vehicle {
    width: calc(54% - 32px);
}

.block-content {
    margin-top: 16px;
    width: 100%;
}

.block-content .input {
    width: 100%;
}

.block-title {
    color: #333;
    font-size: 12px;
}

.block-title span {
    display: block;
}

.block-title span:nth-child(2) {
    opacity: .5;
    margin-top: 8px;
    line-height: 1.25;
}

.numbers-switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.numbers-switcher .switcher-item {
    display: flex;
    align-items: center;
}

.numbers-switcher .radio-title {
    color: #333;
    font-size: 12px;
    margin-left: 8px;
}

.search-blocks .grayed-out-overlay {
    /* background: #e5e5e5 */
}

.search-blocks .grayed-out-overlay .block-title span {
    color: rgba(51, 51, 51, .33);
}

.search-blocks .grayed-out-overlay .part-number-plate input, .search-blocks .grayed-out-overlay .part-number-plate .findButton {
    background-color: #E5E5E5;
    border: 1px solid #B3B3B3;
    color: #999999;
    opacity: 0.8;
    cursor: not-allowed;
}

.search-blocks .grayed-out-overlay input[type="text"]:hover {
    background-color: #E5E5E5!important;
    border: 1px solid #B3B3B3!important;;
    color: #999999!important;;
}

.search-blocks .grayed-out-overlay .part-number-plate input:hover, .search-blocks .grayed-out-overlay button:hover {
    border-color:rgb(217, 217, 217);
}

@media (max-width: 1280px) {

    .search-blocks {
        flex-wrap: wrap;
    }

    .search-blocks .block-reg {
        order: 1;
        width: calc(50% - 8px);
        margin-bottom: 16px;
    }

    .search-blocks .block-pn {
        order: 2;
        width: calc(50% - 8px);
        margin-bottom: 16px;
    }

    .search-blocks .block-vehicle {
        order: 3;
        width: 100%;
    }

    .search-blocks .tooltip-text {
        top: calc(100% + 10px);
        left: auto;
        right: 0;
        transform: none;
    }

    .search-blocks .tooltip-text.textRight::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #283555;
        top: -5px;
        right: 3px;
    }
}

@media (max-width: 768px) {

    .search-blocks .block-reg,
    .search-blocks .block-pn {
        width: 100%;
    }
}

/* Search Blocks Refactored */