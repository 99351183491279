.toggle-advanced-search span {
    display: block;
    min-width: 22px;
    border-radius: 10px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #EB3B3B;
    margin-left: 10px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}