.tooltip-wrap {
    position: relative;
}

.tooltip-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
}

.tooltip-text {
    position: absolute;
    background: #283555;
    color: #fff;
    align-items: center;
    min-width: 330px;
    border-radius: 3px;
    padding: 8px 16px;
    z-index: 1000;
    display: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
    line-height: 1.5;
}

.tooltip-text i {
    color: #F2DE3F;
    margin-right: 10px;
}

.tooltip-text span {
    font-size: 12px;
}

.tooltip-icon:hover ~ .tooltip-text {
    display: flex;
}

.tooltip-text.textRight {
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
}

.tooltip-text.textRight::after {
    content: '';
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;   
    border-right:5px solid #283555;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.tooltip-text.textLeft {
    left: auto;
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
}

.tooltip-text.textLeft::after {
    content: '';
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;   
    border-left:5px solid #283555;
    right: auto;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}