.slider {

    &__primary-item {
        margin-bottom: $space-sm;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        padding-bottom: 56.25%;
        border-radius: $border-radius-base;
    }

    &__secondary {
        @include flex(row, flex-start, flex-start);
    }

    &__item {
        flex-basis: calc(33.333% - #{$space-xs});
        margin-right: $space-sm;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        padding-bottom: 18.75%;
        border-radius: $border-radius-base;

        &:last-child {
            margin-right: 0;
        }
    }
}