.vehicle {
    @include flex(row, center, flex-start);
    background-color: #63666A;
    // $color-background-inverse;
    min-height: 63px;
    border-radius: $border-radius-base;
    border: 1px solid $color-border-dark;
    color: $color-text-inverse;
    padding: 0 $space-md;
    margin-bottom: $space-md;

    &--sticky {
        position: fixed;
        top: 65px;
        left: 50%;
        transform: translateX(-50%);
        width: 1280px;
        z-index: 100;
    }

    &__icon {
        margin-right: $space-lg;
    }

    &__name {
        margin-right: $space-lg;
        font-weight: 500;
    }

    &__count {
        padding: $space-xs 0 $space-xs $space-lg;
        border-left: 1px solid $color-border-inverse;
    }

    &__filter {
        margin-left: auto;
        height: 32px;
        padding: 0 $space-xs;
        border-radius: $border-radius-base;
        background-color: $color-background;
        border: 1px solid $color-border;
        cursor: pointer;
        @include flex(row, center, center);

        i {
            margin-right: $space-xxs;
        }

        &:hover {
            background-color: $color-background-dark;
        }
    }
}