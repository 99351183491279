ul.pagination-wrapper {
    margin-bottom: 0;
    display: flex;
    margin-top: 15px;
    justify-content: center;
}
ul.pagination-wrapper li.page-item.active a.page-link {
    color: #445565 !important;
    background-color: #e3e7eb !important;
    border-color: #ced4da !important;
}
ul.pagination-wrapper a.page-link {
    padding: 5px 15px;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: #6b88a4;
    font-weight: 900;
    font-size: 1rem;
}
ul.pagination-wrapper a.page-link:hover {
    background-color: #f4f4f4;
}

@media screen and (max-width: 320px) {

    ul.pagination-wrapper a.page-link {
       padding: 5px 10px; 
    }  
}