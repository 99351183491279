.auth-wrapper {
    display: flex;
    position: relative;
    height: 100vh;
    background: #fff;
    margin-top: -85px;
    z-index: 1000;
}

.auth-wrapper .small {
    /* width: calc(100% / 4); */
    width: 22.5%;
    height: 100%;
    /* background: url("../images/q-bg.jpg") no-repeat; */
    background-size: cover;
    background-position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0px;
    overflow: hidden;
}

.auth-wrapper .small.quickco {
    width: calc(100% * 3 / 4);
}

.auth-wrapper .small.quickco .slick-slider,
.slick-list,
.slick-track,
.slide-item-wrap,
.slide-item {
    height: 100%;
    width: 100%;
    position: relative;
}

.auth-wrapper .small.quickco .slick-slider .slick-slide > div {
    height: 100%;
}

.auth-wrapper .small.quickco .slick-slider .slide-logo {
    position: absolute;
    top: 30px;
    right: 30px;
}

.auth-wrapper .big {
    width: calc(100% * 3 / 4);
    height: 100%;
    background: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
}

.auth-wrapper .big.quickco {
    width: calc(100% * 1 / 4);
}

.auth-wrapper .small .caption {
    text-align: center;
}

.auth-wrapper .small .caption button {
    display: block;
    width: 200px;
    height: 50px;
    border: none;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    margin: 0px auto;
    background: #F9A700;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 2px;
    cursor: pointer;
    outline: none!important;
    text-transform: uppercase;
}

.auth-wrapper .small .caption.stoneacre h1,
.auth-wrapper .small .caption.stoneacre p,
.auth-wrapper .small .caption.eurobrake h1,
.auth-wrapper .small .caption.eurobrake p {
    color: #fff;
}

.auth-wrapper .small .caption.stoneacre button {
    background: #348e44;
}

.auth-wrapper .small .caption.stoneacre p {
    color: white;
}

.auth-wrapper .small .caption.eurobrake button {
    background: rgb(0, 157, 216);
}

.auth-wrapper .small .caption.eurobrake p {
    color: white;
}

.auth-wrapper .small .caption h1 {
    font-size: 2rem;
    color: #1c1b1a;
    letter-spacing: 10px;
}

.auth-wrapper .small .caption p {
    font-size: 1rem;
    margin: 60px 0px;
    /* color: #B2B2B2; */
    color: #5C5C5C  ;
}


.auth-wrapper .small .caption.gt p {
    color: #fff;
}

.auth-wrapper .small .caption.newNordic p {
    color: white;
}

.auth-wrapper .big {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-logo {
    position: relative;
    z-index: 10000;
    display: block;
    margin: 0px auto 100px auto;
}

.login-form-nda {
    color: #5B5B5B;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: .8rem;
    margin-bottom: 16px;
}

.login-form-nda span {
    color: #F9A700;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.nda-right {
    width: 17px;
    height: 17px;
    margin-right: 10px;
}

.nda-right input {
    height: 100%;
    width: 100%;
}

.nda-popup {
    padding: 50px 30px;
    background: #fff;
    border: 4px solid #F8A700;
    height: 700px;
    width: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:10000;
    overflow-y: scroll;
    color: #000;
    box-shadow: 1px 1px 10px grey;
    overflow-x: hidden;
}

.nda-popup.nk {
    border-color: #FC3000;
}

.nda-popup i {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.nda-popup.default i {
    color: #F8A700;
}

.nda-popup.nk i {
    color: #FC3000;
}

.auth-wrapper .small .caption-register.nk .heading {
    color: #FC3000;
}

.auth-wrapper .small .caption-register.gt .heading {
    color: white;
}

.auth-wrapper .small .caption-register.titan .heading {
    color: white;
}

.auth-wrapper .small .caption-register.nk p, .auth-wrapper .small .caption-register.gt p, .auth-wrapper .small .caption-register.titan p {
    color: white;
}

.auth-wrapper .small .caption-register.nk button {
    background: #FC3000;
}

.auth-wrapper .small .caption-register.gt button {
    background: #FF271E;
}

.auth-wrapper .small .caption-register.titan button {
    background: black;
}


/*.auth-wrapper .small .bg-part-3 {*/
    /*position: absolute;*/
    /*width: 250px;*/
    /*height: 250px;*/
    /*background: url("../images/Part3.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*right: -80px;*/
    /*bottom: -80px;*/
    /*opacity: 0.15;*/
/*}*/

/*.auth-wrapper .small .bg-part-2 {*/
    /*position: absolute;*/
    /*width: 350px;*/
    /*height: 350px;*/
    /*background: url("../images/Part2.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*top: -70px;*/
    /*left: -140px;*/
    /*opacity: 0.15;*/
/*}*/

/*.auth-wrapper .small .bg-part-7 {*/
    /*position: absolute;*/
    /*width: 200px;*/
    /*height: 200px;*/
    /*background: url("../images/Part7.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*left: 5%;*/
    /*bottom: 15%;*/
    /*opacity: 0.25;*/
/*}*/

/*.auth-wrapper .small .bg-part-6 {*/
    /*position: absolute;*/
    /*width: 120px;*/
    /*height: 120px;*/
    /*background: url("../images/Part6.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*right: 10%;*/
    /*top: 30%;*/
    /*opacity: 0.2;*/
/*}*/

/*.auth-wrapper .small .bg-part-1 {*/
    /*position: absolute;*/
    /*width: 300px;*/
    /*height: 300px;*/
    /*background: url("../images/Part1.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*left: -5%;*/
    /*top: -5%;*/
    /*opacity: 0.2;*/
    /*display: none;*/
/*}*/

/*.auth-wrapper .small .bg-part-4 {*/
    /*position: absolute;*/
    /*width: 150px;*/
    /*height: 110px;*/
    /*background: url("../images/Part4.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*left: 5%;*/
    /*bottom: 5%;*/
    /*opacity: 0.2;*/
    /*display: none;*/
/*}*/

/*.auth-wrapper .small .bg-part-5 {*/
    /*position: absolute;*/
    /*width: 150px;*/
    /*height: 150px;*/
    /*background: url("../images/Part5.svg") no-repeat;*/
    /*background-size: 100%;*/
    /*top: 60%;*/
    /*right: 2%;*/
    /*opacity: 0.15;*/
    /*display: none;*/
/*}*/
