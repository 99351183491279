.sample-btn {
    display: inline-block;
    color: #000;
}

.sample-wrap {
    text-align: right;
    margin-bottom: 20px;
}

.sample-wrap i {
    color: green;
    margin-right: 5px;
}