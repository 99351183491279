.pdf-wrapper {
    width: 100%;
    margin: 0 auto;
    /* border: 1px solid gray; */
    padding: 24px;
    position: relative;
}

.pdf-mg-bottom-25 {
    margin-bottom: 15px;
}

.pdf-font-size-sm {
    font-size: 12px;
}

.pdf-bold {
    font-weight: bold;
}

.pdf-checkbox {
    width: 20px;
    height: 20px;
}

.pdf-relative {
    position: relative;
}

.pdf-header {
    display: flex;
    justify-content: space-between;
    height: 2%;
}

.pdf-header div {
    width: 50%;
}

.pdf-header-title {
    width: 50%;
    text-align: center;
}

.pdf-footer {
    display: flex;
    justify-content: space-between;
    /* height: 2%; */
}


@media print {
    body{
        /* width: 21cm; */
        height: 100%;
   } 
}

.pageA4 {
    /* width: 210mm;
    height: 297mm; */
}