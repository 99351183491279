.change-user-credentials{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 0px;
}
.add-user-cancel-save-buttons-wrapper{
    margin-bottom: 50px;
    margin-top: 25px;
}
.password{
    margin: 0px;
    font-size: 24px;
}
