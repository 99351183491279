.logout-btn {
    position: relative;
    font-size: 36px;
    color: #4276a5;
}

.light .logout-btn{
    position: relative;
    color: #4276a5;
}

.light .home .logout-btn{
    color: #fff;
}

.light .dropdown-item .logout-span {
    color: #666666;
    font-size: 14px;
    line-height: 80px;
    padding: 0px;
}