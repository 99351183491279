.header {
    height: 65px;
    @include flex(row, center, space-between);
    padding: 0 24px;
    border-bottom: 1px solid #DEDEDE;
    background-color: $color-background;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 105;
}

.profile {
    padding: $space-md;
    background-color: $color-secondary;
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #283655;
        position: absolute;
        top: -10px;
        left: 30px;
    }

    &__name {
        color: $color-text-inverse;
        font-weight: 500;
        font-size: $font-size-xl;
        margin-bottom: $space-xs;
    }

    &__email {
        color: $color-text-inverse;
        font-size: $font-size-md;
    }

    &__version {
        color: $color-text-inverse;
        margin-top: $space-sm;
    }
}

.subnav {
    background-color: $color-background;
    padding: $space-xs 0;

    &__item {
        margin: $space-xs 0;
    }

    &__link {
        @include flex(row, center, flex-start);
        padding: $space-xs $space-md;
        font-size: $font-size-md;
        color: $color-text;
        cursor: pointer;

        &:hover {
            background-color: #EAEBEF;
            color: $color-text;
        }

        .badge {
            margin-left: $space-sm;
        }
    }
}

.language-selector{
    padding: 24px 20px;
    position: absolute;
}
.language-selector ul.submenu {
    background-color: white;
    padding: 0px 9px;
    position: absolute;
    left: -1px;
    top: 100%;
    max-height: 0px;
    overflow: hidden;
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    transition-duration: 0.3s;
}
.language-selector ul.submenu li{
    padding: 10px 10px;
    cursor: pointer;
    &:first-child{
        padding-top: 5px;
    }
}
.language-selector:hover ul.submenu{
    max-height: 145px;
    padding-bottom: 10px;
}