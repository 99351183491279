body, html {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: $color-background-dark;
    font-size: $font-size-lg;
    color: $color-text;
}

.grayline {
    height: 1px;
    background-color: $color-border;
    margin: $space-lg 0;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
}

.content {
    margin-top: 89px;
    padding-bottom: $space-lg;
}

.page-content {
    padding: 0 $space-lg $space-lg 104px;
}

.branch-details, .acc-details {

    .selector {
        width: 100%;
    }

    .input:not(:last-child), .selector {
        margin-bottom: $space-md;
    }
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($color: $color-secondary, $amount: .25);
    z-index: 1050;
}

.overlay-loading {
    @extend .overlay;
    z-index: 1200;
}

.additional-info {
    min-height: 100px;
    overflow-y: auto;
    width: 350px;
    border: 1px solid $color-border;
    padding: $space-md;
    border-radius: $border-radius-base;
    color: $color-text;
    word-wrap: break-word;
    text-align: left;
    resize: none;
}

.auto-generate {
    cursor: pointer;
    color: $color-text-active;
}

.auth-container {
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.copy-partnumber {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.empty-page {
    border: 2px dashed $color-border-dark;
    max-width: 400px;
    margin: 150px auto 0 auto;
    padding: $space-xxl;
    text-align: center;

    h1 {
        font-weight: 400;
        margin-bottom: $space-xxl;
        text-transform: capitalize;
    }
}

.invalid-error {
    display: flex;
    align-items: center;
    border: 1px solid $color-danger;
    border-radius: $border-radius-base;
    background-color: #fff1f0;
    padding: $space-sm;

    i {
        font-size: $font-size-xxxl;
        margin-right: $space-xs;
        color: $color-danger;
    }
}

.search .block {
    height: 100%;
    justify-content: space-between;
}

.vcount {
    @include flex(row, center, flex-start);
    position: absolute;
    right: $space-md;
    top: $space-md;

    &__text {
        color: $color-danger;
        font-size: $font-size-md;
        margin-right: $space-xxs;

        &--single {
            color: $color-success;
        }
    }
}

.search {

    .input {
        width: 100%;
    }
}

.no-image {

    img {
        max-height: 200px;
    }
}

.custom-row-underline {
    cursor: pointer;
}

.viewer-open {
    padding: 0!important;
}

.imgs {
    width: 360px;
    height: 200px;
    .image {
        width: auto;
        max-height: 190px;
        margin: 0 auto;
        max-width: 100%;
    }
}

.no-image-found {
    @include flex(row, center, center);
    height: 89px;
    text-align: center;
}

.brands-list {

    ul {
        margin-top: $space-md;

        li {
            cursor: pointer;
            margin-bottom: $space-xxs;
        }
    }
}