.priceLevels{
    display: -webkit-flex;
    -webkit-flex-direction: column;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 250px;
    margin-top: 15px;
  }

  .page{
    color: black;
  }

  .displayinline{
    display: inline-block;
    position: relative;
    bottom: 6px;
  }
  .displayinline.bold{
    font-weight: bold;
  }
  .inline{
    display: inline-block;
  }
  .priceLevelsTableHeaderInfo{
    width:237px!important;
    height: 166px;

    background-color: #273655;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    font-family: Arial;
    padding: 15px;
    padding: 0px;
  }

  .priceLevelsTableHeaderInfo p{
    line-height: 41px;
    margin: 0;
    margin-left: 15px;
  }

  .priceLevelsTableLevels{
    width: 160px;
    height: 166px;
    display: inline-block;
    text-transform: capitalize;
    color: #273655;
    font-family: Arial;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    margin-bottom: 15px;
    text-align: center;
    background: #FFF;
    border-right: 1px solid #dedede;
  }


  .LevelsWrapper{
    display: flex;
    align-items: center;
  }

  .priceLevelsTableLevels p{
    border-bottom: 1px solid #dedede;
    height: 41px;
    margin: 0px;
  }

  .priceLevelsTableLevels p:last-child{
    border-bottom: none;
  }

.deleteLevel{
    color: #848484;
    font-size: 14px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteLevel:hover{
  color: red;
  cursor: pointer;
}

.input-container-z {
    position: relative;
    height: 41px;
    border-bottom: 1px solid #dedede;
    font-weight: bold;
  }
.input-container-z.amount{
  padding: 5px 25px;
}

.input-container-z.amount p{
  border:0px;
}
.input-container-z.amount .inp-z{
  width:40%;
  display: inline-block;
  text-align: center;
}
.input-container-z .inp-z {
    font-family: 'Arial';
    font-weight: 400;
    font-size: 12px;
    border: none;
    width: 70%;
    padding: 0;
    -moz-appearance: textfield;
    appearance: textfield;
    background: #F5F5F5;
    border: 1px solid #dedede;
    height: 32px;
    border-radius: 3px;
    margin: 0 auto;
    text-align: center;
}

.input-container-z .inp-z::-webkit-inner-spin-button,
.input-container-z .inp-z::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-container-z .inp-z:focus {
    outline: none;
    border-color: #4276A6;
}

.btn-z {
    padding: 10px 40px;
    margin: 20px auto;
    background-color: #4276A6;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.btn-z:hover {
    box-shadow: 0 0 20px rgba(0, 255, 144, 0.53);
}

.btn-z:focus {
    outline: none;
}
.newLevelWrapper{
  display: inline-block;
    position: relative;
}
.addBUttonWrapper{
  margin-left: 15px;;
}

.addBUttonWrapper .custom-btn-base{
  cursor: pointer;
  border: none;
  background-color: #4276A6;
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  font-family: Arial;
  padding: 8px;
  outline:none;
}

.createNew{
  float: left;
  width: 70%;
}
.plusIcon{    
  float: left;
  font-size: 26px;
  width: 30%;
  position: relative;
  top: 5px;
}
.productTitle h1{
  font-size: 18px;
    font-family: Arial;
    color: #4D4F5C;
    font-weight: bold;
    text-transform: uppercase;
    margin:0px!important;
}

.input-container-z .price-level-name-error {
  border-color: red;
}