.hide{
    display: none;
    opacity: 0;
}
.show{
    z-index: 999;
    display: block;
    opacity: 1;
}

.modal{ 
    overflow: scroll;
    padding-right: 0px;
    z-index: 10001;
}
.modal .dashboard-page-body{
    padding-bottom: 0px;
}

.dashboard-user-preferences-body{
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.add-user-modal-left{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 40%;
}

.light  .add-user-modal-left{
    /* border:1px solid #d9d9d9; */
}

.add-user-modal-inputs-wrapper{
    display: -webkit-flex;
    -webkit-flex-direction: column;
    display: flex;
    flex-direction: column;
    background: #fff;
    /* background-color: rgba(255,255,255,0.1); */
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 700px;
}

.user-preferences-group-section-wrapper{
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-around;
}

.user-preference-stock-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}

.user-preference-section-select-wrapper {
    width: 45%;
}

.add-user-preferences-save-button.update-brands{
    width: 73%;
    text-align: center;
    padding: 5px 0;
    margin: 0;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    margin-top: 20px;
    color: white;
    font-weight: bold;
    padding: 13px 43px;
    font-size: 1.1rem;
    border-radius: 6px;
    cursor: pointer;
    background-image: -moz-linear-gradient( 90deg, rgb(30,162,12) 0%, rgb(63,192,32) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(30,162,12) 0%, rgb(63,192,32) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(30,162,12) 0%, rgb(63,192,32) 100%);
}
.add-user-preferences-save-button:hover{
    border-radius: 5px;
    background-image: -moz-linear-gradient( 90deg, rgb(34,189,13) 0%, rgb(73,217,37) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(34,189,13) 0%, rgb(73,217,37) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(34,189,13) 0%, rgb(73,217,37) 100%);
}

.sortable-brands-wrapper ul{
    overflow-y: hidden;
    max-height: 490px;
}

.sortable-brands-wrapper ul:hover {
    overflow-y: scroll;
}

.dashboard-user-preferences-body input, .dashboard-user-preferences-body select {
    background-color: rgba(255,255,255,0.1);
    padding: 11px;
    color: #fff;
    outline: none;
    cursor: pointer; 
    border: none;
    margin: 6px 0px;
}

.light .dashboard-user-preferences-body input,.light .dashboard-user-preferences-body select {
    color:#7a8a90;
    border-width: 1px;
    border-color: rgb(217, 217, 217);
    border-style: solid;
    background-image: -moz-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
}

.user-preferences-right{
    width: 58%;
    min-height: 850px;
    background: rgba(255,255,255,0.1);
}

.light .user-preferences-right {
    background: #fff;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
}   

.user-preferences-etc {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.user-preferences-header{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 20px;
}
.user-preferences-group-section-elements, .dragable-list-elements-wrapper-limb{
    position: relative;
    margin-bottom: 20px;
    min-width: 296px;
}

.user-preferences-group-section-wrapper .user-preferences-group-section-elements:first-child > .manage-preferences-elements {
    margin-left: 0px;
}

.user-preferences-group-section-elements .manage-preferences-elements {
    width: 296px;
    max-width: 296px;
    padding: 20px;
    margin-left: 20px;
    border: 1px solid #D9D9D9;
}

.light .manage-preferences-elements li{
    background-color: #f9f9f9;
    color: #000;
    border: 1px solid #DBDBDB;
}

.light .manage-preferences-elements li.drag-checked {
    color: #fff;
    background-color: #3964E6;
    background-image: none;
}

.manage-preferences-elements li {
    list-style-type: none;
    margin: 10px 0;
    font-size: 1rem;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    padding: 7px 15px;
    cursor: pointer;

    border-width: 1px;
    border-color: rgb(217, 217, 217);
    border-style: solid;
    background-image: -webkit-linear-gradient( 90deg, rgb(242,242,242) 0%, rgb(255,255,255) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(242,242,242) 0%, rgb(255,255,255) 100%);
}

.user-preferences-group-section-elements .manage-settings-elements {
    width: 296px;
    max-width: 296px;
    padding: 20px;
    margin-left: 20px;
    border: 1px solid #D9D9D9;
}

.user-preferences-header span{
    padding-left: 15px;
    color: #808080;
    font-weight: bold;
    font-size: 1rem;
} 

.user-preferences-button {
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 25px 0; 
}
.user-preferences-item-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px;
}



.modal .manufactures-elements-wrapper {
    width: 296px;
    max-width: 296px;
    padding: 20px;
    border: 1px solid #D9D9D9;
}

.additional-elements-wrapper{
    padding: 5px 0px;
    align-items: center;    
} 

.manage-additional-elements li,.manage-manufacture-elements li {
    list-style-type: none;
    margin: 10px 0;
    font-size: 1.1rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: -webkit-linear-gradient( 90deg, rgb(242,242,242) 0%, rgb(255,255,255) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(242,242,242) 0%, rgb(255,255,255) 100%);
    padding: 7px 15px;
}

.light .manage-additional-elements li,.light .manage-manufacture-elements li {
    background-color: #f9f9f9;
    color: #000;
    border: 1px solid #DBDBDB;
}


.sortable-element-name,.element-name{
    width: 100%;
    font-size: 0.775rem;
}

.user-preferences-select2{
    padding: 2px;
    font-size: 0.875rem;
    color: #fff;
    outline: none;
    margin: 2px 0px;
}

.user-preferences-swither,.user-preferences-select2{
    padding: 2px;
    font-size: 0.875rem;
    color: #fff;
    outline: none;
    margin: 2px 0px;
}

.light .user-preferences-swither,.light .user-preferences-select2{
    color:#7a8a90;
}

.user-preferences-manufacture-title{
    margin-top: 20px;
    padding: 2px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 21px;
    outline: none;
}

.light .user-preferences-manufacture-title{
    color: #808080;
    font-weight: bold;
}

.user-preferences-stock-select option{
    background-color: rgb(3, 32, 53);
    border:0px;
}

.light .user-preferences-stock-select option{
    border-width: 1px;
    border-color: rgb(217, 217, 217);
    border-style: solid;
    background: #fff;
    background-image: -moz-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
}

.user-preferences-brand-section-title{
    padding: 2px;
    font-size: 1.1rem;
    color: #fff;
    outline: none;
    margin: 2px 0px;
}

.user-preferences-stock-select-50{
    width: 30%;
}

.user-preferences-stock-select-50 option{
    background: rgb(3, 32, 53);
    border:0px;
}

.light .user-preferences-stock-select-50 option{
    border-width: 1px;
    border-color: rgb(217, 217, 217);
    border-style: solid;
    background: #fff;
    background-image: -moz-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(227,228,232) 0%, rgb(250,251,255) 100%);
}

.user-preferences-stock-select{
    width: 100%;
}

.user-preferences-span-hr{
    width: 100%;
    height: 2px;
    background: rgba(255,255,255,0.1);
    margin: 5px 0;
    opacity: 0.5;
}

.light .user-preferences-span-hr {
    background: #E6E6E6;
}

.overflow-auto{
    max-height: 775px;
    overflow: hidden;
}
.overflow-auto:hover {
    overflow-y: auto;
}

/* Select All Begin*/
.selectAll.user-preferences-checkall {
    margin-bottom: 10px;
    margin-top: 0px;
}

.selectAll {
    margin: 10px 0 5px 0;
    font-size: 1.1rem;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2px 16px;
}

.selectAllLable {
    font-size: 0.7rem;
    padding-right: 10px;
    margin-bottom: 0px;
    color:#fff;
    font-family:Arial;
    font-size: 0.75rem; 
}

.light .selectAllLable {
    color: #A0A0A0;
}
    /* Select All End*/

/*---------- CheckBox  Begin -----------*/

.md-checkbox {
  position: relative;
  height: 1.2em;;
  /* margin: 1em 0; */
  /* text-align: left; */
}
.md-checkbox.md-checkbox-inline {
  display: inline-block;
}

.md-checkbox label:not(:empty) {
  padding-left: 0.75em;
}
.md-checkbox label:before, .md-checkbox label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.md-checkbox label:before {
    width: 100%;
    height: 100%;
    background: #c22325;
    cursor: pointer;
    top: 0px;
    left: 0;
    content: "\1F5D9";
    font-size: 13px;
    color: #FFF !important;
    text-align: center;
}

.light .md-checkbox label:before {
    background: #F23132;
}

.md-checkbox input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: 1.2em;
    height: 1.2em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
}

.md-checkbox input[type="checkbox"]:checked + label:before {
  background: green;
  border: none;
  content: ''; /* this one for disable X */
}

.light .md-checkbox input[type="checkbox"]:checked + label:before {
    background: #35BE19;
}

.md-checkbox input[type="checkbox"]:checked + label:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    border: 0.15em solid #fff;
    border-top-style: none;
    border-right-style: none;
}
.md-checkbox input[type="checkbox"]:disabled + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}
.md-checkbox input[type="checkbox"]:disabled:checked + label:before {
  background: rgba(0, 0, 0, 0.26);
}
/*---------------- CheckBox End---------------------*/

.user-pref-arrows-icons{
    display: -webkit-flex;
    display: flex;
    /* width: 30%; */
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
}

.user-pref-arrows-icons i{
    line-height: 0.2;
}

@media (min-width: 900px){
    .modal-dialog{
        max-width: 90%;   
    }
    /* .modal-dialog-centered{
        min-height: 100%;
    } */
}

.GridLoaderInner{
    position: absolute;
    margin: 0 auto;
    text-align: center;
    left: 40%;
    top: 40%;
    z-index: 5;
}

.user-preferences-group-section-wrapper .user-preferences-group-section-elements:first-child > .dragable-list-elements-wrapper {
    margin-left: 0;
}
