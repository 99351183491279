.react-datepicker__input-container {

    input {
        width: 100%;
        height: $space-xl;
        border-radius: $border-radius-base;
        border: 1px solid $color-border;
        background: $color-background-dark;
        padding: 0 $space-sm;
        font-size: $font-size-md;
        color: $color-text;

        &:hover {
            border: 1px solid $color-border-dark;
        }

        &:focus {
            background-color: $color-background;
            border: 1px solid $color-primary;
        }
    }
}