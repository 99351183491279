.theme-icon {
    font-size: 25px;
    position: relative;
    margin-right: 10px;
}

.theme-dropdown {
    position: absolute;
    top: 120%;
    left: -50%;
    background: #fff;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    z-index: 10000;
    min-width: 250px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.theme-icon.light .theme-dropdown {
    background: #F2F2F2;
}

.theme-icon.light .theme-dropdown::before {
    border-bottom: 10px solid #eeeeee;
}

.theme-dropdown::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 15px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.theme-icon .theme-dropdown {
    display: none;
}

.home .theme-icon{
    display: none;
}

.theme-icon.visible .theme-dropdown {
    display: block;
}

.theme-item {
    font-size: .8rem;
    display: flex;
    align-items: center;
    padding: 8px 0px;
    color: #868686;
}

.theme-item label {
    margin-bottom: 0px;
    margin-left: 10px;
    cursor: pointer;
}

.theme-icon i {
    color: #004698;
    cursor: pointer;
}

.theme-icon.light i {
    color: #3b8bea;
}

.theme-checker input {
    display: none;
}

.theme-icon .theme-checker i {
    cursor: initial;
}

.theme-item.dark .theme-checker i {
    color: #004698;
    font-size: 25px;
}

.theme-item.light .theme-checker i {
    color: #3b8bea;
    font-size: 25px;
}

.theme-item.dark:hover {
    color: #004698;
}

.theme-item.light:hover {
    color: #3b8bea;
}