.not-found-wrapper {
    margin: 70px auto;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.not-found-wrapper p {
    margin-top: 16px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #424242;
    display: block;
}