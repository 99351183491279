.dropzone {
    height: 251px;
    background: $color-background;
    border: 1px dashed $color-border-dark;
    border-radius: $border-radius-base;
    @include flex(column, center, center);
    color: $color-text;

    &__icon {
        color: transparentize($color: $color-icon, $amount: .5);
    }

    &__title {
        font-size: $font-size-xxxl;
        font-weight: 600;
        margin-top: $space-sm;
    }

    &__browse {
        font-size: $font-size-md;
        margin-top: $space-sm;

        span {
            color: $color-primary;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__formats {
        font-size: $font-size-md;
        color: transparentize($color: $color-icon, $amount: .5);
        margin-top: $space-xl;

        &--size {
            margin-top: $space-xxs;
        }
    }

    &__input {
        display: none;
    }
}