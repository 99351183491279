.pdf-section {
    display: flex;
    justify-content: space-evenly;
}

.pdf-mg-top {
    margin-top: 10px;
}

.pdf-card {
    position: relative;
    width: 45%;
}

.pdf-card-top {
    text-align: center;
}

.pdf-flex-verticaly-centred {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pdf-flex-centred {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.pdf-card-badge {
    position: absolute;
    left: 0;
    top: 0;
}

.pdf-card-top h3 {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    padding: 0;
}

.pdf-flex {
    display: flex;
    justify-content: space-between;
}

.pdf-form-input{
    margin-bottom: 10px;
}

.pdf-card-left {
    width: 70%;
}

.pdf-card-witch-20 {
    width: 20%;
}

.pdf-card-witch-20 p {
    width: 80%;
}

.pdf-card-right {
    width: 25%;
}

.pdf-card-right img {
    width: 100%;
}

.pdf-card-left input {
    height: 30px;
}

.pdf-card-footer {
    margin-top: 2%;
}

.pdf-card-address-body {
    padding: 5px;
    margin-top: 5px;
    height: 100%;
    border: 1px solid gray;
}

.pdf-card table {
    width: 100%;
}

.pdf-table td input{
    width: 100%;
    height: 100%;
    border: none;
}

.pdf-card-address-wrapper {
    width: 65%;
    margin-top: 2%;
    float: right;
}

.pdf-table, .pdf-table th, .pdf-table td{
    border: 1px solid gray;
    border: 1px solid black;
    border-collapse: collapse;
}

.pdf-table th {
    padding: 5px 0;
}

.pdf-table td {
    padding: 2px;
}

.pdf-sub-ordered-list {
    margin-left: 1%;
}

.pdf-list-warpper {
    margin-top: 1%;
}

.pdf-list-warpper > div {
    margin-top: 5px;
}

.pdf-list-warpper ol li {
    margin-top: 5px;
}

.pdf-reason-desc {
    margin-top: 5px;
}

.pdf-returnprocessing-wrapper {
    padding: 10px;
    border: 1px solid gray;
}

.pdf-returnprocessing-wrapper div:last-child {
    margin-bottom: 0;
}

.pdf-underline {
    width: 70%;
    border-bottom: 1px solid gray;
}

.pdf-site-link {
    display: block;
    margin-top: 15px;
}

.pdf-fake-input {
    width: 50%;
    height: 30px;
    border: 1px solid gray;
}

.pdf-fake-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid gray;
    position: relative;
    margin: 0px 5px;
}

.pdf-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid black;
}

.pdf-checkbox[type="checkbox"] {
    display: grid;
    place-content: center;
}

.pdf-checkbox[type="checkbox"]::before {
    content: "✕";
    width: 20px;
    height: 20px;
    transform: scale(0);
}

.pdf-checkbox[type="checkbox"]:checked::before {
    transform: scale(1);
    background: #fff !important;
    content: "✕";
    font-size: 18px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 3px;
}

.not-editable-box {
    border: 1px solid black;
    height: 50px;
    display: block;
}

.border-botom-dashed {
    border-bottom: 1px dashed black;
}
