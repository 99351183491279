.badge {
    font-size: $font-size-sm;
    color: $color-text-inverse;
    border-radius: 12px;
    border: 2px solid $color-border-inverse;
    padding: $space-xxs $space-xs;
    font-weight: bold;
    display: inline-block;

    &--success {
        background-color: $color-success;
    }

    &--warning {
        background-color: $color-warning;
    }

    &--danger {
        background-color: $color-danger;
    }

    &--neutral {
        background-color: $color-neutral;
    }

    &--purple {
        background-color: $color-purple;
    }
}