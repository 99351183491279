@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'UKNumberPlate';
    src: url('../../fonts/UKNumberPlate.eot');
    src: url('../../fonts/UKNumberPlate.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/UKNumberPlate.woff2') format('woff2'),
        url('../../fonts/UKNumberPlate.woff') format('woff'),
        url('../../fonts/UKNumberPlate.ttf') format('truetype'),
        url('../../fonts/UKNumberPlate.svg#UKNumberPlate') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

