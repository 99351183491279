.multi-col {
    display: flex;
    justify-content: space-between;
}

.multi-col .form-group {
    width: 49%;
}

.registerWrapper select{
    background: #F2F2F2;
    border: none;
    height: 40px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding-left: 30px;
    width: 100%;
}

.login-form input.input-error {
    border: 1px solid #e00505;
}

.login-form input.input-error::placeholder {
    color: #e00505;
    font-weight: bold;
}

.login-form input.input-error::-ms-input-placeholder {
    color: #e00505;
    font-weight: bold;
}

.login-form input.input-error::-ms-input-placeholder {
    color: #e00505;
    font-weight: bold;
}

.registerWrapper select.input-error {
    border: 1px solid #e00505;
}

.allow-reg-warning {
    color: #717171;
    text-align: center;
    font-size: 1.1rem;
}

.reg-error {
    color: red;
    display: block;
    margin-top: 5px;
}

.registration-on-success {
    text-align: center;
}

.registration-on-success img {
    width: 100px;
    height: 100px;
    opacity: 1;
}

.registration-on-success h3 {
    height: 76px;
    margin: 32px 0px 26px 0;
    text-align: center;
    font: normal normal medium 32px/38px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.registration-on-success p {
    text-align: center;
    font: normal normal normal 18px/27px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}


.register-form-body  input,
.register-form-body  select {
    padding-left: 12px;
}