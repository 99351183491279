.modal {
    display: none;
    overflow: hidden;

    &--active {
        display: block;
    }

    &__backdrop {
        @extend .overlay;
    }

    &__dialog {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1100;
        border-radius: $border-radius-base;
        background-color: $color-background;
        box-shadow: $box-shadow-dark;
        width: 100%;
        border: 1px solid $color-border;

        &--sm {
            width: 500px;
        }
    
        &--md {
            width: 800px;
        }
    
        &--lg {
            width: 1100px;
        }

        &--xl {
            width: 1300px;
        }

        &--scrollable { 
            max-height: 90%;
            overflow: auto;
        }
    }

    &__header {
        padding: $space-md $space-lg;
        border-bottom: 1px solid $color-border;
        background-color: $color-background;
        @include flex(row, center, space-between);
    }

    &__content {
        padding: $space-lg;
    }

    &__close {
        cursor: pointer;
        color: $color-text-light;
        font-size: $font-size-xxl;

        &:hover {
            color: $color-text;
        }
    }

    &__title {
        font-size: $font-size-xl;
        color: $color-text;
        font-weight: 500;
        text-transform: capitalize
    }

    &__actions {
        @include flex(row, center, flex-end);
        margin-top: $space-lg;
    }

    &--dark {

        .modal__dialog {
            background-color: $color-background-dark;
        }
    }
}