.cart-summary {
    background: rgba(255,255,255,0.1);
    /* margin-right: 20px; */
    /* height: 242px; */
    text-align: center;
    /* -webkit-box-shadow: 0 0 2px gray;
    box-shadow: 0 0 2px gray; */
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
}
.light .cart-summary{

    background:rgba(255,255,255,0.6);
}
.cart-summary__header {
    font-size: 1rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px 15px;
    /* color: wheat; */
}

.cart-summary__footer{
    padding: 0 15px;
}

.cart-summary-pr-amount{
    padding: 5px 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
}
.light .cart-summary-pr-amount{
    color: #333;
}
.text-bold{
    font-weight: bold;
}

.cart-summary-checkout {
    font-size: 1rem;
    width: 100%;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 15px 0px;
    text-align: center;
    margin: 0 auto;
    display: block;
    text-decoration: none !important; 
    background-color: #0052a7;
    background-image:linear-gradient(to top, #0052a7 , #007cc4)
}

.cart-summary-checkout:hover {
    cursor: pointer;
    color: white;
    background-image: linear-gradient(to bottom,rgb(0,141,222) 0%, rgb(0,96,191) 100%);
 
}

.light .cart-summary-checkout{
    border-radius: 5px;
    background-image: linear-gradient( 90deg, rgb(46,108,184) 0%, rgb(59,139,235) 100%);
}

.light .cart-summary-checkout:hover {
    cursor: pointer;
    color: white;
    background-image: linear-gradient( 90deg, rgb(52,123,209) 0%, rgb(64,150,255) 100%);
 
}

.cart-summary-divider{
    height: 1px;
    width: 100%;
    margin: 5px 0;
    background: #c8c8c8;
}
.car-summary-p{
    font-size: 0.7rem;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #fff;
}
.light .car-summary-p{
    color:#333;
}
.go-to-checkout {
    margin: 20px 0px;
    font-weight: bolder;
    display: inline-block;
    border: 1px solid #7a7a7a;
    color: white;
    padding: 10px 20px;
    border-radius: 2px;
    text-decoration: none!important;
    background: rgba(255,255,255,0.01);
    /* background-image: -moz-linear-gradient( 90deg, rgb(106,106,106) 0%, rgb(146,146,146) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(106,106,106) 0%, rgb(146,146,146) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(106,106,106) 0%, rgb(146,146,146) 100%); */
}

.light .go-to-checkout, .light .go-to-checkout:hover, .light .go-to-catalogue{
    background-image: linear-gradient(to top, #6a6a6a, #747474, #7e7e7e, #888888, #929292);
    color: #fff;
}

.light .go-to-catalogue:hover{
    background-image: linear-gradient( 90deg, rgb(102,102,102) 0%, rgb(179,179,179) 100%);
}

.go-to-checkout:hover {
    color:white;
    background: rgba(255,255,255,0.35);
    /* background-image: -moz-linear-gradient( 90deg, rgb(133,133,133) 0%, rgb(171,171,171) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(133,133,133) 0%, rgb(171,171,171) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(133,133,133) 0%, rgb(171,171,171) 100%); */
}