.account-switcher {
    @include flex(row, center, space-between);

    &__wrapper {
        width: 500px;        
    }

    &::before {
        content: '';
        height: 1px;
        display: block;
        width: 25%;
        background-color: $color-border;
    }

    &::after {
        content: '';
        height: 1px;
        display: block;
        width: 25%;
        background-color: $color-border;
    }
    
    &__selector {
        width: 210px;
    }

    &__clear {
        @include flex(row, center, center);
    }

    &__icon {
        margin-right: $space-xs;
    }

    &__name {
        margin-right: $space-md;
        font-weight: 500;
    }
}