.exc{
    color: red;
    margin-left: 5px;
    cursor: pointer;
    position:relative;
}

.infoTooltip{
    width:200px;
    height: 115px;
    border:1px solid #666f80;
    background-color: #fff;
    position: absolute;
    color:#808080;
    bottom:15px;
    padding-top:15px;
}

.Close{
    font-size: 24px;
    position: absolute;
    right:-5px;
    top:-5px;
}