.action-popup-wrapper {
    position: relative;
}

.action-popup-btn {
    height: 32px;
    width: 32px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.action-popup-btn.active, .action-popup-btn:hover {
    border: 1px solid #DEDEDE;
    border-radius: 50%;
    box-shadow: 0px 3px 7px #00000026;
}

.action-popup-content {
    position: absolute;
    top: 70%;
    right: 50%;
    z-index: 1001;
    width: 154px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 7px #0000001A;
    border: 1px solid #DEDEDE;
    border-radius: 3px 0px 3px 3px;
}

.action-popup-cont-ul {
    width: 100%;
    list-style-type: none;
}

.action-popup-cont-ul li {
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    color: #333333;
    box-sizing: border-box;
}

.action-popup-cont-ul li.action-li-disabled {
    background:#ebeced;
}

.action-popup-cont-ul li:hover {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

.action-popup-cont-ul li span {
    padding-left: 10px;
}

.action-popup-cont-ul li.action-popup-delet {
    color:#EB3B3B;
}

.action-popup-btn-i {
    font-size: 16px;
    color: #808080;
}

.action-backdrop {
    background: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
}