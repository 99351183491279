.hamburger-container {
    display: none;
    align-items: center;
    cursor: pointer;
}

.hamburger-wrap {
    display: flex;
    align-items: center;
}

.hamburger-wrap .hamburger-text {
    text-transform: uppercase;
    color: #333;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.hamburger-wrap .hamburger {
    width: 27px;
    height: 18px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    margin-right: 10px;
}

.hamburger-wrap .hamburger .bar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; 
    height: 100%;
    
}

.hamburger-wrap .hamburger .bar .line {
    position: absolute;
    width: 100%;
    height: 3px;
    background: #000;
}

.hamburger-wrap .hamburger .bar .line:nth-child(1) {
    top: 0;
}

.hamburger-wrap .hamburger .bar .line:nth-child(2) {
    top: 7px;
}

.hamburger-wrap .hamburger .bar .line:nth-child(3) {
    top: 14px;
}

.hamburger-container.active .hamburger-text {
    color: #4175A6;
}

.hamburger-container.active .hamburger .bar .line {
    background: #4175A6;
}

.hamburger-container.active .hamburger .bar .line:nth-child(1) {
    transform: rotate(45deg) translate(10px, 0px);
}

.hamburger-container.active .hamburger .bar .line:nth-child(2) {
    opacity: 0;
}

.hamburger-container.active .hamburger .bar .line:nth-child(3) {
    transform: rotate(-45deg) translate(10px, 0px);
}

.nav-overlay {
    position: fixed;
    width: 100%;
    height: calc(100% - 65px);
    top: 65px;
    background: rgba(0,0,0,.33);
    opacity: 0;
    right: 0;
    max-height: 0;
}

.nav-container {
    position: fixed;
    right: -200px;
    top: 65px;
    width: 200px;
    height: calc(100% - 65px);
    background: #fff;
    border: 1px solid #DEDEDE;
    border-top: none;
    border-left: none;
    z-index: 3;
    padding-top: 20px;
}

.nav-container .actions-content {
    display: block;
    height: auto;
}

.nav-container .actions-content .topIconsLinks {
    height: 65px;
    padding: 0px 20px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    border-top: none;
}

.nav-container .actions-content .topIconsLinks span {
    margin-right: 20px;
}

.nav-container .actions-content .topIconsLinks.active {
    border-top: none;
    border-left: 3px solid #4175A6;
    color: #4175A6;
}

.nav-container .actions-content .topIconsLinks.active span {
    color: #4175A6;
}

.nav-container .dropdown-menu.show {
    left: auto!important;
    top: 0!important;
    right: calc(100% + 20px);
}

.nav-container .dropdown-menu.show .dropdown-div-profile:before {
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid #283555;
    top: 35px;
    transform: translateY(-50%);
    right: -20px;
    left: auto;
}

.nav-container .dropdown-menu.show .dropdown-div-profile-item {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.nav-container .dropdown-menu.show .dropdown-div-profile p {
    font-weight: 200;
    opacity: .5;
    font-size: 12px!important;
}

.nav-container .actions-content .topIconsLinks .notification-icon {
    margin: 0;
    margin-right: 15px;
}

.nav-container .actions-content .topIconsLinks .compare-icon {
    margin: 0;
    margin-right: 15px;
}

.hamburger-container.active .nav-container {
    right: 0;
}

.hamburger-container.active .nav-overlay {
    opacity: 1;
    max-height: initial;
}

@media (max-width: 1100px) {

    .actions-content {
        display: none;
    }

    .hamburger-container {
        display: flex;
    }
}

@media (max-width: 768px) {

    .nav-container .dropdown-menu.show {
        position: relative!important;
        left: auto!important;
        right: auto!important;
        min-width: auto!important;
        top: 5px!important;
        margin-bottom: 10px!important;
    }

    .nav-container .dropdown-menu.show .dropdown-div-profile:before {
        display: none!important;
    }
}