.filters {
    background-color: $color-background;
    width: 304px;
    border-left: 1px solid $color-border;
    position: fixed;
    height: calc(100% - 65px);
    right: -304px;
    top: 65px;
    z-index: 50;

    &--active {
        right: 0;
        z-index: 100;
    }

    &--section {
        position: absolute;
        right: $space-lg;
        top: $space-md;
        border-left: none;
        height: auto;
        width: auto;
        @include flex(row, center, flex-start);

        .filters__item {
            width: auto;
            padding: 0 $space-md;
            margin-left: 8px;

            &--selected {
                background-color: $color-primary;

                span {
                    color: $color-text-inverse;
                }
            }
            
            span {
                font-size: $font-size-lg;
            }
        }
    }

    &__list-container {
        @include flex(row, center, flex-start);
    }

    &__list-title {
        font-weight: 500;
    }

    &__title {
        @include flex(row, center, space-between);
        padding: $space-md;
        border-bottom: 1px solid $color-border;

        span {
            font-size: $font-size-xl;
            font-weight: 500;
        }

        i {
            color: transparentize($color: $color-icon, $amount: .5);
            cursor: pointer;

            &:hover {
                color: transparentize($color: $color-icon, $amount: 0);
            }
        }
    }

    &__content {
        padding: $space-md;
    }

    &__list {
        @include flex(row, flex-start, flex-start);
        flex-wrap: wrap;
    }

    &__item {
        border: 1px solid $color-border;
        border-radius: $border-radius-base;
        height: $space-xl;
        background: $color-background;
        width: calc(25% - #{$space-xxs});
        margin: 2px;
        @include flex(row, center, center);
        cursor: pointer;

        &:hover {
            border-color: $color-primary;
        }

        &--selected {
            border-color: $color-primary;
        }

        img {
            max-height: 20px;
            max-width: 80%;
        }

        span {    
            text-transform: capitalize;
            font-weight: 500;
            font-size: $font-size-md;
        }
    }
}