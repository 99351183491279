.vehicle-selectors {
    @include flex(row, flex-start, space-between);
    flex-wrap: wrap;

    &__selector {
        width: 19%;

        &:first-child {
            margin-bottom: $space-xs;
        }
    }

    &__btn {
        width: 39.25%;
        text-transform: uppercase;
        font-weight: 600;
    }
}