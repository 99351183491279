.alt-products-wrapper {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow-y: auto;
    height: 193px;
    overflow-x: hidden;
}

.alt-products-wrapper table {
    background: rgba(255, 255, 255, 0.05) !important;
    text-align: center;
}

.alt-products-wrapper table {
    border: 1px solid gainsboro;
}

.light .alt-products-wrapper table td {
    border-color: #DEDEDE;
    padding: 10px 20px;
}

.alt-products-wrapper table th, .alt-products-wrapper table td {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.alt-products-wrapper table thead tr {
    background: rgba(255, 255, 255, 0.1);
}

.alt-products-wrapper table tbody tr th {
    color: #fff;
    font-size: 0.8rem;
    font-weight: normal
}

.light .alt-products-wrapper table tbody tr th {
    color: #333;
    border-top: 2px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
}

.alt-products-wrapper table thead tr th {
    color: #fff;
    font-weight: bold;
    font-size: 0.8rem;
}

.light .alt-products-wrapper table thead tr th {
    padding: 10px 20px;
    color: #fff;
    background: #283555;
}

.alt-products-wrapper table thead tr th {
    border: 0px;
}

.alt-products-wrapper table thead tr {
    border: 1px solid #283555;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.shopping-cart-length-inner {
    display: inline-block;
    text-align: center;
}

.disabled-add-to-cart {
    background-color: #E5E5E5;
    border: 1px solid #B3B3B3;
    background-image: none;
    cursor: not-allowed;
    color: #999999;
}

.add-to-cart-btn, .add-to-compare-btn {
    display: inline-flex;
    text-transform: capitalize;
}

.sectionTable td {
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
}

.full-info-account {
    display: flex;
    justify-content: space-between;
}

.last-supplier-update {
    text-transform: capitalize;
}

.last-supplier-update p{
    margin: 0px;
    margin-left: 7px;
    display: inline-block;
}

.last-supplier-update i {
    font-size: 0.8rem;
    color: #FA2601;
    display: inline-block;
}

.td-action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.light .fullInfo .tab-content .tab-pane {
    z-index: auto;    
}

@media screen and (max-width: 768px) {

    .alt-products-wrapper table tbody tr td:before {
        content: attr(data-attr);
        font-weight: 700;
    }

    .alt-products-wrapper table {
        border: none;
    }

    .alt-products-wrapper table thead {
        display: none;
    }

    .alt-products-wrapper table tr {
        height: auto;
        display: block;
        margin-bottom: 16px;
        border: 1px solid #DEDEDE;
        border-top: 3px solid #263355;
    }

    .alt-products-wrapper table tr:last-child {
        margin-bottom: 0;
    }

    .alt-products-wrapper table tr td {
        width: 100%!important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 15px;
        min-height: 48px;
        border: none;
        border-bottom: 1px solid #DEDEDE;
    }

    .alt-products-wrapper table tr td:last-child {
        border-bottom: none;
    }

    .last-supplier-update {
        margin-top: 5px;
        font-size: 10px;
    }

    .light .alt-products-wrapper table td {
        padding: 0px 16px;
    }

    .full-info-account {
        flex-direction: column;
        align-items: flex-end;
    }
}