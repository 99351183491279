.message-inner {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    padding: 5px;
    border-radius: 3px;
    position: relative;
}

.message-inner span {
    margin-left: 10px;
}

.message-inner i {
    font-size: 18px;
}

.message-inner::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 20px;
    width: 20px; 
    height: 13px; 
    /* border-left: 10px solid transparent;
    border-right: 10px solid transparent; */
}

.message-inner.message-warning {
    color: #CCA429;
    background: #FFF6D9;
    border: 1px solid #E6D8AC;
}

.message-inner.message-warning::before {
    /* border-top: 10px solid #E6D8AC; */
    background: url('/images/Yellow-Triangle.png') no-repeat;
}

.message-inner.message-success {
    color: #2DB23E;
    background: #E3F0E4;
    border: 1px solid #2DB23E33;
}

.message-inner.message-success::before {
    /* border-top: 10px solid #2DB23E33; */
    background: url('/images/Green-Triangle.png') no-repeat;
}