/*Base classes*/
.line-w-17{
    width: 16.7%;
    text-align: center;
}
/*End */

.cart-product-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 40px;
    height: 148px;
    color: #fff;
    background: rgba(255,255,255,0.1);
    align-items: center;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
}

.light .cart-product-wrapper{
    background:rgba(255, 255, 255, 0.6);
    color: #000;
}

.products-list-wrapper .cart-product-wrapper:nth-child(2){
    margin-top: 0px;
}

.products-list-wrapper .cart-product-wrapper{ 
    margin-top: 20px;
}
  
.cart-product-wrapper:last-child {
    border:none;
}
.shoppingCartWrapper {
    /* background-color: rgba(255,255,255,0.1); */
    flex-direction: row;
    padding-bottom: 150px;
}
.user-info{
    color:#1f1f1f;
}
.shopping-cart-header{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: #fff;
    /* background-color: rgba(255,255,255,0.1); */
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.light .shopping-cart-header{
    color:#333;
}

.shopping-cart-body {
    width: 100%;
    /* padding-top: 40px; */
    padding-bottom: 40px;
    border-top:1px solid rgba(255,255,255,0.1);
}

.cart-table-left{
    padding-left: 0px;
}

.cart-summary-right{
    padding-right: 0px;
}

.centered-title{
    padding-top: 100px;
    padding-bottom: 40px;
    text-align: center;
}
.centered-title-h1{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0px;
}

.light .centered-title-h1{
    color: #333;
}

.centered-title-p{
    font-size: 0.875rem;
    line-height: 20px;
    margin: 0 auto;
    max-width: 80%;
}

.products-list {
    min-height: 400px;
    /* background:white; */
    /* box-shadow: 0 0 2px grey */
    /* margin-left: 20px; */
}

.products-list-wrapper {
    color:black;
}

.products-in-card-label {
    padding:0px 40px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    overflow: hidden;
    color: #fff;
    background:rgba(255,255,255,0.1);
    border-bottom-style: outset;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
    border-bottom-color: rgba(191, 191, 191, 0.23137254901960785);
    border-bottom-width: 2px;
}
.light .products-in-card-label{
    background: #666F80;
    color: #fff; 
}

.products-in-card-label > ul {
    width: 100%;
    white-space: nowrap;
}
.products-in-card-label > ul > li {
    list-style: none; 
    /* font-family: 'Oswald', san-serif; */
    /* font-weight: bold; */
    /* text-transform: uppercase; */
    /* font-size: 0.9rem; */
    display: inline-block;
    justify-content: center;
}