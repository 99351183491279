.notify {
    display: none;

    &--active {
        display: block;
    }

    &__dialog {
        border-radius: $border-radius-base;
        padding: $space-lg;
        max-width: 480px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-background;
        border: 1px solid $color-border;
        box-shadow: $box-shadow-dark;
        z-index: 1101;
    }

    &__backdrop {
        @extend .overlay;
    }

    &__content {
        @include flex(row, flex-start, flex-start);
        text-align: left;
    }

    &__icon {
        display: block;
        color: $color-background;
        font-size: 40px;
        margin-right: $space-md
    }

    &__text {
        color: $color-text;
        width: calc(100% - 76px);
    }

    &__primary-text {
        font-size: $font-size-xl;
        font-weight: 700;
        line-height: 19px;
    }

    &__secondary-text {
        font-weight: 400;
        margin-top: $space-sm;
        line-height: 21px;
    }

    &__close {
        color: $color-text-light;
        font-size: $font-size-lg;
        cursor: pointer;
        position: absolute;
        right: $space-sm;
        top: $space-sm;

        &:hover {
            color: $color-text;
        }
    }

    &__actions {
        margin-top: $space-xl;
        @include flex(row, center, flex-end);

        span {
            margin-left: $space-xs;
        }
    }

    &__data {
        margin-top: $space-lg;
        max-height: 330px;
        overflow-y: auto;

        ul {

            li {
                font-weight: 500;
                margin-bottom: 5px;
            }
        }
    }
}