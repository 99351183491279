.table {

    &--type-2 {

        .table__td {
            border-right: none;
            padding: $space-md;

            &--entire-row {
                padding: $space-md;
            }

            &:last-child {
                border-right: 1px solid $color-border;
            }
        }
    }

    &__header {
        @include flex(row, center, space-between);
        margin-bottom: $space-sm;
    }

    &__table {
        width: 100%;
        border-spacing: 0;
    }

    &__thead {
        background-color: #63666A;
        // $color-secondary;
        overflow: hidden;
    }

    &__tbody {
        background-color: $color-background;

        > .table__tr {

            &:hover {
                background-color: $color-background-dark;
            }
        }

        &--collapsed {
            background-color: $color-background-dark;
        }
    }

    &__tr {

        &:last-child {

            .table__td {

                &:first-child {
                    border-bottom-left-radius: $border-radius-base;
                }

                &:last-child {
                    border-bottom-right-radius: $border-radius-base;
                }
            }
        }
    }

    &__th, &__td {
        padding: $space-xs $space-md;
        height: $table-cell-height;
        text-align: center;
    }

    &__td {
        border-bottom: 1px solid $color-border;
        border-right: 1px solid $color-border;

        &.textLeft {
            text-align: left;
        }

        &.textRight {
            text-align: right;
        }

        &.textUpperCase {
            text-transform: uppercase;
        }

        &:first-child {
            border-left: 1px solid $color-border;
        }
    }

    &__th {
        font-weight: 500;
        color: $color-text-inverse;
        text-transform: capitalize;

        &.textLeft {
            text-align: left;
        }

        &.textRight {
            text-align: right;
        }

        &:first-child {
            border-top-left-radius: $border-radius-base;
        }

        &:last-child {
            border-top-right-radius: $border-radius-base;
        }
    }

    &__th-icon {
        margin-left: $space-sm;
    }
}