
.form-wrapper{
    margin-top: 30px;
}
.user-account-forms{
    width: 100%;
    font-size: 16px;
    color: #b5b5b5!important;
}
.user-account.dashboard-add-user-body{
    display: block;
}
.user-account label{
    color: #41ACFE;
    font-size: 0.8rem;
}
.user-account span{
    font-size: 14px;
    color: #ce0000;
}
.user-account .add-user-left{
    width: 100%;
}


.form-wrapper{
    margin-top: 0;
}

.add-user-save-button{

}