.enter_reg_number_label {
    color:#fff;
    margin-bottom: 15px;
    text-align: left;
}

.light .enter_reg_number_label{
    color: #333;
}

.catalogueMenu .left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.choose_search_option_label {
    font-size: 2rem;
    color:#808080;
    text-transform: uppercase;
    margin: 40px 0px;
}

.catalogueMenu .right #pills-tab {
    display: flex;
    justify-content: flex-end;
}

.catalogueMenu .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catalogueMenu .right ul {
    margin: 30px 0px;
}

.catalogueMenu .searchType.active {
    background: #0066b8;
    border-radius: initial;
}

.search-type-label {
    font-size: 1.2rem;
}

.catalogueMenu .searchType.active img {
    filter: invert(1);
}

.catalogueMenuIcon {
    height: 100%;
    z-index: 5;
}

a.searchType {
}

a.searchType.active .catalogueMenuIcon{
    opacity: 1;
}

a.searchType:hover .catalogueMenuIcon {
    opacity: 1;
}

.catalogueMenuIcon.Plate{
    opacity: 0.5;
}

.nav-item .catalogueMenuIcon{
    padding-top: 4px;
}

.catalogueMenuIcon.Plate, .light a.searchType.active .catalogueMenuIcon.Plate,  .light a.searchType:hover .catalogueMenuIcon.Plate{
    color: #fff;
    font-size: 30px;
}
.light a.searchType .catalogueMenuIcon.Plate{
    opacity: 1;
    color: #333;
    font-size: 30px;
}

.catalogueMenuIcon.Vehicle {
    opacity: 0.5;
}

.catalogueMenuIcon.Vehicle,  .light a.searchType.active .catalogueMenuIcon.Vehicle,  .light a.searchType:hover .catalogueMenuIcon.Vehicle{
    color: #fff;
    font-size: 30px;
}

.light .catalogueMenuIcon.Vehicle{
    opacity: 1;
    color: #333;
    font-size: 30px;
}

.catalogueMenuIcon.Partnumber{
    opacity: 0.5;
}

.catalogueMenuIcon.Partnumber, .light a.searchType.active .catalogueMenuIcon.Partnumber, .light a.searchType:hover .catalogueMenuIcon.Partnumber{
    color: #fff;
    font-size: 30px;
}

.light .catalogueMenuIcon.Partnumber{
    opacity: 1;
    color: #333;
    font-size: 30px;
}


.searchType {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 112px;
    color: #818181;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 16px 0px;
}

.catalogueMenu .nav-item {
    margin: 0px 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 0px;
    border-color: #8d8d8d;
    min-width: 210px;
}

.searchType p {
    margin-bottom: 0px;
}

.searchType i {
    font-size: 42px;
}