.image-slider {
    padding-top: $space-xxl;

    .slider-primary {

        .slides-container {
            border-radius: $border-radius-base;
            background-color: $color-background-dark;
            border: 1px solid $color-border;
            @include flex(row, flex-start, flex-start);
            flex-wrap: wrap;
            padding: $space-xs;
    
            .add-slide {
                background: $color-background;
                height: 119px;
                width: calc(14.28% - #{$space-sm});
                @include flex(column, center, center);
                cursor: pointer;
                margin: $space-xxs;
                border: 1px solid $color-border;
                border-radius: $border-radius-base;
    
                &:hover {
                    box-shadow: $box-shadow-lighter;
                }
    
                div {
                    color: $color-text;
                    font-size: $font-size-xl;
                    font-weight: 500;
                    text-align: center;
    
                    &:last-child {
                        font-size: $font-size-md;
                        opacity: .5;
                        margin-top: $space-xs;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    
    .slider-secondary {
        @include flex(row, flex-start, space-between);
        margin-top: 64px;
    
        .box {
            width: calc(33.3% - #{$space-sm});
        }
    
        .upload-img {
            @include flex(row, center, space-between);
    
            .img {
                width: calc(50% - #{$space-xs});
                height: 100px;
                border-radius: $border-radius-base;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: bottom;
                position: relative;
    
                &.initial {
                    background-color: $color-background-dark;
                }
                
                &:hover {
    
                    .slide-trash {
                        opacity: 1;
                    }
                }
    
                .slide-trash {
                    @include flex(row, center, center);
                    position: absolute;
                    top: $space-sm;
                    right: $space-sm;
                    background: $color-background;
                    padding: 2px;
                    box-shadow: $box-shadow-lighter;
                    border-radius: $border-radius-base;
                    cursor: pointer;
                    opacity: 0;
                }
            }
    
            button {
                @include flex(column, center, center);
                width: calc(50% - #{$space-xs});
                height: 100px;
                background: none;
                border: 1px dashed $color-border-dark;
                outline: none;
                cursor: pointer;
                font-size: $font-size-lg;
                border-radius: $border-radius-base;
    
                &:hover {
                    box-shadow: $box-shadow-lighter;
                }
    
                span {
                    display: block;
                    margin-top: $space-xxs;
                }
            }
        }
    
        .site-address {
            @include flex(row, center, space-between);
            margin-top: $space-lg;
    
            span {
                font-size: $font-size-md;
                color: $color-text;
            }
    
            .input {
                width: 60%;
            }
        }
    }
}

.main-slide {
    width: calc(14.28% - #{$space-xs});
    height: 119px;
    margin: $space-xxs;
    border: 1px solid $color-border;
    border-radius: $border-radius-base;
    background: $color-background;

    &:hover {
        box-shadow: $box-shadow-lighter;

        .slide-img {

            .slide-trash {
                opacity: 1;
            }
        }
    }

    .slide-img {
        height: 90px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        position: relative;

        .slide-trash {
            @include flex(row, center, center);
            position: absolute;
            top: $space-sm;
            right: $space-sm;
            background: $color-background;
            padding: 2px;
            box-shadow: $box-shadow-lighter;
            border-radius: $border-radius-base;
            cursor: pointer;
            opacity: 0;
        }
    }

    .slide-footer {
        @include flex(row, center, flex-start);
        height: 29px;
        padding: 0 0 0 $space-sm;

        .slide-drag {
            cursor: grab;
        }

        .slide-title {
            color: $color-text;
            font-size: $font-size-md;
            margin-left: $space-xs;
            max-height: $space-sm;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50%;
            overflow: hidden;
        }

        .slide-status {
            @include flex(row, center, center);
            margin-left: auto;
            cursor: pointer;
            height: 88%;
            width: $space-xl;

            &:hover {
                background-color: $color-background-dark;
            }
        }
    }
}

.box {
    padding: $space-lg;
    background: $color-background;
    border: 1px solid $color-border;
    border-radius: $border-radius-base;
    position: relative;

    &__header {
        @include flex(row, center, space-between);
        padding-bottom: $space-sm;
        border-bottom: 1px solid $color-border;
    }

    &__body {
        padding-top: $space-lg;
    }
}

.box-message {
    position: absolute;
    right: 0;
    top: -50px;
}

.images {
    margin-top: $space-lg;

    &__item {
        @include flex(row, center, space-between);
        margin-bottom: $space-sm;

        &--invalid {

            .images__progress {
                background-color: $color-danger;
            }

            .images__name {
                color: $color-danger;
            }
        }
    }

    &__image {
        width: 64px;
        height: 36px;
        border-radius: $border-radius-base;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        -webkit-animation: progressBlur 2s forwards;
        animation: progressBlur 2s forwards;
    }

    &__content {
        width: calc(100% - 80px);
    }

    &__name {
        @include flex(row, center, space-between);
        width: 100%;
        font-size: $font-size-md;

        i {
            cursor: pointer;
            color: transparentize($color: $color-icon, $amount: .5);
        }
    }

    &__progress {
        height: $space-xs;
        width: 0;
        border-radius: $border-radius-base;
        background: $color-primary;
        margin-top: $space-sm;
        transition: all 1s ease-in-out;
        -webkit-animation: progress 1s forwards;
        animation: progress 1s forwards;
    }
}