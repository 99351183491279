.pagination {
    @include flex(row, center, center);
    margin-top: $space-lg;

    &__item {
        border: 1px solid $color-border;
        border-radius: $border-radius-base;
        padding: $space-xxs $space-xs;
        color: $color-text;
        cursor: pointer;
        margin: 0 2px;

        &--active {
            background-color: $color-primary;
            color: $color-text-inverse;
            border: 1px solid $color-border-active;
            cursor: initial;
        }

        &--dots {
            cursor: initial;
        }
    }
}