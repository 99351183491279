@import "../../admin/administrator/Menu.css";

.account-row{
    width:100%;
    margin-top: 60px;

}
.pdr-0{
    padding-right:0px; 
}
.back-btn{
    float: right;
}
.user-img{
    z-index: 2;
    border-radius: 50%;
    width: 125px;
    height: 125px;
    border: 0;
    box-shadow: -1px 4px 3px #000;
    position: relative;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -32px;
    background-position: center;
    background-size: cover;
}
.user-name{
    width: 185px;
    min-height: 41px;
    border-radius: 2px;
    border: 1px solid #5F7280;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    font-size: 0.85rem;
    padding: 10px;
    z-index: 5;
}
.light .user-name, .light .user-menu-button{
    color:#333;
}
.user-img-header{
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    background: #02457A;
    z-index: 0;
}
.light .user-img-header{
    background: #3b8bea;
}
.user-menu-colomn{
    min-height: 500px;
    background: rgba(255, 255, 255, 0.1);
    border: 0;
    padding: 7px;
}
.light .user-menu-colomn{
    background-color: rgb(252, 253, 255);
}
.user-menu-button{
    width: 185px;
    height: 46px;
    border: 0;
    color: white;
    font-size: 1.0rem;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 10px;
    cursor: pointer;
}
.light .user-menu-button{
    background-image: linear-gradient(to top, #e4e5e9, #e9eaee, #eff0f4, #f4f5f9, #fafbff);
    border:1px solid #D9D9D9;
}
.user-menu-button:focus{
    outline: none!important;
}
.user-menu-button:hover, .user-menu-button.active{
    background-color: rgba(255, 255, 255, 0.2);
}
.light .user-menu-button:hover, .user-menu-button.active{
    background-image: linear-gradient(to top, #e4e5e9, #e9eaee, #eff0f4, #f4f5f9, #fafbff);
}
#settings1{
    margin-top: 20px;
}

.nopd{
    padding: 0;
}
.menu-buttons-wrapper{
    margin-top: -20px;
    text-align: center;
}
.Usercollapse{
    padding: 1px;
    position: relative;
    overflow: hidden;

}
.Usercollapse.show{
    padding: 7px;
    height: auto;
}
.Usercollapse .columns{
    min-height: 50px;
    text-align: center;
    width: 185px;
    margin: 0 auto;
}
.columns ul li.nav-item, .Usercollapse .columns ul li{
    list-style-type: none;
    font-size: 14px;
    width: 100%;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 1px;
}
.light .columns ul li.nav-item, .light .Usercollapse .columns ul li{
    background:#F2F2F2;
    color: #fff;
    border:1px solid #D9D9D9;
}

.light .columns ul li.nav-item,.light .Usercollapse .columns ul li a.text, .light .columns ul li.nav-item,.light .Usercollapse .columns ul li div.text{
    color: #000;
}
.Usercollapse .columns ul li .text{
    color: #BFC9CB;
    text-decoration: none;
    width: 100%;
    font-size: 0.75rem;
    line-height: 36px;
}
.Usercollapse .columns ul li:hover{
    background: rgba(255, 255, 255, 0.2);
}
.userNav.nav-item.active, .userNav.nav-item.active a{
    background-color: #0581E1!important;
    color: #fff!important;
}
.arrow-up {
    width: 0;
    height: 0;
    position: absolute;
    left: 11px;
    top: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #502020;
  }
  .account-row .back-btn{
    position: absolute;
    top: -20px;
  }

  .UserAccountHeader{
    margin: 0px 15px;
    font-size: 15px;
    height: 60px;
    padding: 15px;
    text-align: center;
    font-size: 1.2rem;
    background-color: #02457A;
}

.light .UserAccountHeader{
    background: #3b8bea;
    color: #fff;
}