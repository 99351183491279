@mixin flex($direction, $align, $justify) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin flex-inline($direction, $align, $justify) {
    display: inline-flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin background($url, $repeat, $size, $position) {
    background: url($url);
    background-repeat: $repeat;
    background-size: $size;
    background-position: $position;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}