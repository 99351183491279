.capture-screenshot {
    display: flex;
    align-items: center;
    justify-content: center;
}

.capture-screenshot i {
    font-size: 36px;
    color: #4276a5;
    cursor: pointer;
}

.capture-screenshot i:hover {
    color: #283655;
}

.screenshot-wrapper {
    width: 100%;
}

.screenshot-modal {
    width: 700px!important;
}