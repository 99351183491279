.block {
    background-color: $color-background;
    border: 1px solid $color-border;
    border-radius: $border-radius-base;
    padding: $space-lg;
    @include flex(column, flex-start, flex-start);
    position: relative;

    &__content {
        margin-top: $space-lg;
        width: 100%;
    }

    &__title {
        font-weight: 500;
        color: $color-text;
        font-size: $font-size-xl;
        width: 100%;
        text-transform: capitalize;
    }

    &__subtitle {
        opacity: .5;
        display: block;
        margin-top: $space-xs;
        line-height: 1.2;
        font-weight: 400;
    }

    &--type-2 {
        padding: $space-md;

        .block__title {
            font-size: $font-size-md;
            font-weight: 400;
        }

        .block__content {
            margin-top: $space-md;
        }
    }

    &--type-3 {
        @include flex(row, center, space-between);

        .block__content {
            margin-top: 0;
            width: auto;
        }

        .block__title {
            width: auto;
        }
    }

    &--type-4 {
        padding: 0;
        border: none;
        background: none;
        border-radius: none;

        .block__title {
            font-size: $font-size-lg;
            font-weight: 500;
            margin-bottom: $space-sm;
        }

        .block__content {
            padding: $space-sm;
            background: $color-background-dark;
            border: 1px solid $color-border;
            margin-top: 0;
            border-radius: $border-radius-base;
            max-height: 358px;
            overflow: auto;
        }
    }

    &--type-5 {
        padding: 0;
        border: none;
        background: none;
        border-radius: none;

        .block__title {
            font-size: $font-size-lg;
            font-weight: 500;
            margin-bottom: $space-sm;
        }

        .block__content {
            padding: $space-xxs;
            background: $color-background-dark;
            border: 1px solid $color-border;
            margin-top: 0;
            border-radius: $border-radius-base;
            max-height: 358px;
            overflow: auto;
        }
    }

    &--type-6 {
        .table {
            max-height: 320px;
            overflow-y: auto;
        }
    }

    &--disabled {
        opacity: .5;
    }
}