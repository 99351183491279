.set-settings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.set-settings span {
    margin-right: auto;
    color: #333;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.formats-select {  
    margin-right: 10px;
}

.set-settings select {
    height: 32px;
    width: 100px;
    text-transform: capitalize;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding-left: 5px;
    outline: none;
}

.set-settings .durations-select select {
    width: 70px;
}