.alertify-notifier {
    
    .ajs-message {
        border-radius: $border-radius-base;
        color: $color-text-inverse;
        padding-left: 48px!important;
        top: 80px!important;

        &.ajs-success {
            background: $color-success url("/Icons/alertify-check.png") no-repeat!important;
            background-position: $space-md center!important;
            border: 1px solid $color-success-dark!important;
        }

        &.ajs-error {
            background: $color-danger url("/Icons/alertify-exclemation.png") no-repeat!important;
            background-position: $space-md center!important;
            border: 1px solid $color-danger-dark!important;
        }
    }
}