.radio {
    @include flex(row, center, flex-start);
    cursor: pointer;

    &--disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &__label {
        margin-left: $space-xs;
        font-size: $font-size-md;
    }
}