.signin {
    position: fixed;
    left: -1000px;
    top: 0;
    height: 100%;
    padding: $space-xxl;
    @include flex(column, center, flex-start);
    width: 340px;
    background-color: $color-background;
    box-shadow: $box-shadow-light;
    transition: all 1s ease-in;

    &--visible {
        left: 0;
    }

    form {
        margin: auto;
        width: 100%;

        button {
            width: 100%;
            height: 48px;
            border-radius: $border-radius-base;
            border: none;
            cursor: pointer;
            color: $color-text-inverse;
            font-size: $font-size-xxl;
            font-weight: 700;
        }
    }
}
.sign-in-lang{
    span {
        font-size: 20px;
        padding: 5px;
        cursor: pointer;
    }
    span:not(:last-child){
        border-right: 1px solid gray;
    }
    span.active-lang{
        color: rgb(252, 48, 0);
        cursor: default;
    }
}