.setting-details {

    .input {
        margin-bottom: $space-md;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.company-stock-feed {

    .group {
        margin-bottom: $space-md;
    }
}