

.ad-image-top {
    margin-bottom: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 56.25%;
    border-radius: 3px;
}

.ad-image-bottom {
    display: flex;
    flex-wrap: wrap;
}

.ad-image-bottom .item {
    flex-basis: calc(33.333% - 8px);
    margin-right: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 18.75%;
    border-radius: 3px;
}

.ad-image-bottom .item:last-child {
    margin-right: 0;
}