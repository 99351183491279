.btn {
    padding: 0 $space-md;
    height: 32px;
    line-height: 32px;
    color: $color-text-inverse;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-lg;
    border-radius: $border-radius-base;
    outline: none;
    border: 1px solid transparent;
    text-align: center;
    border: none;
    font-weight: 500;
    text-transform: capitalize;

    &--disabled, &:disabled {
        cursor: not-allowed;
        background-color: $color-background-disabled!important;
        border: 1px solid $color-border-disabled!important;
        color: $color-text-lighter;
    }

    &--primary {
        background-color: $color-primary;
        border: 1px solid $color-primary;

        &:hover {
            background-color: $color-primary-light;
            border: 1px solid $color-primary-light;
        }
    }

    &--success {
        background-color: $color-success;
        border: 1px solid $color-success;

        &:hover {
            background-color: $color-success-light;
            border: 1px solid $color-success-light;
        }
    }

    &--danger {
        background-color: $color-danger;
        border: 1px solid $color-danger;

        &:hover {
            background-color: $color-danger-light;
            border: 1px solid $color-danger-light;
        }
    }

    &--cancel {
        background-color: $color-background;
        border: 1px solid $color-border;
        color: $color-text;

        &:hover {
            background-color: $color-background-dark;
        }
    }

    &--secondary {
        background-color: $color-secondary;
        border: 1px solid $color-secondary;

        &:hover {
            background-color: $color-secondary-light;
            border: 1px solid $color-secondary-light;
        }
    }
}