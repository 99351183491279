.shopping-cart-length-inner {
  font-size: 0.7rem;
  color: #ffffff;
  text-decoration: none;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  line-height: 39px;
  margin-right: -1px;
}

.add-to-cart-btn .shopping-cart-length-inner {
  font-weight: bold;
}
  
.add-to-cart-btn .shopping-cart-length-inner {
    right: 0px;
}

.add-to-cart-btn .shopping-cart-length {
    right: 0px;
}

.add-to-cart-btn .shopping-cart-length {
  font-weight: bold;
}

.add-to-cart-btn {
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  padding: 0px 15px;
  cursor: pointer;
  font-size: 0.8rem;
  color: white;
  font-weight: normal;
  height: 40px;
  display: inline-flex;
  align-items: center;
  background-color: #2569A1;
  min-width: 110px;
  justify-content: center;
  position: relative;
}

.add-to-cart-btn:hover:not(.disabled-add-to-cart){
  background-color: #2B79BA;
}

.cart-product-body-footer-quantity__quantity.shopping-cart-length-inner input{
  background-color: transparent;
  -moz-appearance: textfield;
}

.add-to-cart-badge {
    position: absolute;
    font-size: 10px;
    top: -10px;
    color: #fff!important;
    background: #eb3b3b;
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    border: 2px solid #fff;
    align-items: center;
    justify-content: center;
    font-family: Arial;
    padding: 4px 8px;
    right: 0px;
}

