.custom-container {
    border: 1px solid #dedede;
    background: #fff;
    border-radius: 3px;
}

.custom-container-body {
    padding: 12px 24px 24px 24px;
    border-radius: 3px;
    min-height: 50px;
}

.custom-container-header {
    font-weight: 500;
    text-transform: capitalize;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    font-size: 16px;
    color: #333;
    padding: 24px 24px 12px 24px;
    font-family: 'Roboto', sans-serif;
}

.custom-container.darkHeader .custom-container-header {
    background: #283555;
    color: #fff;
}

.custom-container.basic .custom-container-header {
    background: none;
    color: #000;
}

.custom-container.darkHeader .custom-container-body {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.custom-container.headerLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-container.withoutBg {
    background: none;
    border: none;
}

.custom-container.headerLeft .custom-container-header,
.custom-container.headerLeft .custom-container-body {
    padding: 24px;
}

.custom-container.darkHeader .custom-container-header,
.custom-container.darkHeader .custom-container-body {
    padding: 24px;
}