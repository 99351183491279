.login-btn {
    padding: 0px 5px;
    cursor: pointer;
    color: #fff;
}

.logout-btn {
    color: #fff;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    padding: 50px;
    z-index: 1000;
}

.form-footer button {
    display: block;
    border: none;
    background: #F9A700;    
    margin: 0px auto;
    outline: none!important;
    cursor: pointer;
    width: 320px;
    height: 48px;
    text-align: center;
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0.45px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 3px;
    opacity: 1;

}

.big.quickco .form-footer button {
    width: 100%;
    display: block;
    border: none;
    background: #F9A700;
    border-radius: 2px;
    color: white;
    font-weight: bold;
    height: 50px;
    margin: 0px auto;
    outline: none!important;
    font-size: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
}

.login-modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    max-width: 500px;
    padding: 70px;
    z-index: 10000;
}

.close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: #000;
}

.login-form-header {
    /* width: 262px; */
    height: 38px;
    text-align: center;
    font: normal normal normal 32px/38px Roboto;
    letter-spacing: 3.2px;
    color: #333333;
    opacity: 1;
}

.login-form-title {
    font-family: "Montserrat" !important;
    font-weight: 700;   
    letter-spacing: 0px;
    text-transform: none !important;
    color: #000;
    text-align: center;
    font-size: 1.7rem;
}

.login-form-body-footer {
    margin-top: 50px;
    color:#999999;
    text-align: center;
}

.no-account-label {
    margin-bottom: 10px;
}

.reg-link {
    text-transform: uppercase;
}

.login-form-body {
    margin-top: 32px;
    margin-bottom: 16px;
    /* margin: 25px 0px; */
}

.login-modal .login-heading h3{
    color: #000;
    text-align: left;
}

.loginWrapper .error,.global-error{
    color: #ff0505;
    text-align: left;
    margin-top: 15px;
    display: block;
    font-size: 0.85rem;
    background: #ffeaea;
    padding: 5px 15px;
    border-radius: 2px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #ff0000;
    font-weight: bold;
}

.loginWrapper .error::after {
    position: absolute;
    width:10px;
    height: 10px;
    border-left:1px solid #ff0000;
    border-top: 1px solid #ff0000;
    top: 0;
    left: 20px;
    margin-left: -5px;
    content: '';
    transform: rotate(45deg);
    margin-top: -6px;
    background: #ffeaea;
    /* content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ff0000;
    position: absolute;
    left: 10px;
    top: -6px; */
}

.global-warning {
    margin: 24px 0px;
    background: #E6A11712 0% 0% no-repeat padding-box;
    border: 1px solid #E6A11724;
    border-radius: 3px;
    padding: 24px;
    opacity: 1;
    text-align: center;
}

.global-warning-title {
    font-weight: bold;
    font-size: 28px;
    font-family: "Roboto";
    letter-spacing: 0px;
    margin-bottom: 16px;
    color: #E6A117;
}

.global-warning-desc{
    font-size: 16px;
    font-family: "Roboto";
    letter-spacing: 0px;
    line-height: 20px;
    color: #333333;
}

.dashboard-page-wrapper .error {
    color: #f66363;
    text-align: left;
    margin-top: 10px;
    display: block;
    font-size: 0.85rem;
    background: #313131;
    padding: 5px 15px;
    border-radius: 25px;
    margin-bottom: 10px;
    position: relative;
}

.dashboard-page-wrapper .error::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #313131;
    position: absolute;
    left: 20px;
    top: -6px;
}

.registration-error, .registration-success {
    color: green;
    font-weight: bolder;
    text-align: left;
    margin-top: 15px;
    display: flex;
    justify-content: center;
}
.registration-error{
    color: red;
}

.loginWrapper {
    width: 40%;
    max-width: 40%;
}

.big.quickco .loginWrapper {
    width: 70%;
    max-width: 70%;
}

.login-form input {
    background: #F3F2F2;
    height: 40px;
    border: 1px solid #F2F2F2;
    border-radius: 5px;
    padding-left: 30px;
    outline: none!important;
    width: 100%;
    color: #5B5B5B;
    font-size: inherit;
}

.login-inputs-wrapper input  {
    color: #5B5B5B;   
    padding-left: 14px;
}

.login-inputs-wrapper input:hover {
    background: #EDEDED;
    /* cursor: pointer; */
}

.login-inputs-wrapper.nk  input:active {
    border-color: #FC3000;
}

.login-inputs-wrapper.titan  input:active {
    border-color: black;
}

.login-inputs-wrapper.gt  input:active {
    border-color: black;
}

.login-inputs-wrapper.default input:active {
    border-color: #F9A600;
}

.login-inputs-wrapper.nk  input:focus {
    background-color: #F3F2F2 !important;
    border-color: #FC3000 !important;
}

.login-inputs-wrapper.gt  input:focus {
    background-color: #F3F2F2 !important;
    border-color: black !important;
}

.login-inputs-wrapper.titan  input:focus {
    background-color: #F3F2F2 !important;
    border-color: black !important;
}

.login-inputs-wrapper.default input:focus {
    background-color: #F3F2F2 !important;
    border-color: #F9A600 !important;
}


.login-form::-webkit-input-placeholder { /* Edge */
    color: #CFCFCF;
}

.login-form:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #CFCFCF;
}

.login-form::placeholder {
    color: #CFCFCF;
}

.login-form input:focus {
    background: #F2F2F2;
}