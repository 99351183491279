$color-primary: #2569A1;
$color-primary-dark: #1F5887;
$color-primary-light: #2B79BA;
$color-primary-darker: $color-primary;
$color-primary-lighter: #318AD4;

$color-secondary: #283555;
$color-secondary-light: #34466E;
$color-secondary-lighter: #405687;

$color-text: #333333;
$color-text-light: #666666;
$color-text-lighter: #999999;
$color-text-active: $color-primary;
$color-text-inverse: #FFFFFF;

$color-icon: #333333;
$color-icon-light: #666666;
$color-icon-lighter: #999999;
$color-icon-inverse: #FFFFFF;
$color-icon-active: $color-primary;
$color-icon-disabled: #B3B3B3;

$color-background: #FFFFFF;
$color-background-dark: #F5F5F5;
$color-background-darker: #D9D9D9;
$color-background-darkest: #CCCCCC;
$color-background-disabled: #E5E5E5;
$color-background-inverse: $color-secondary;

$color-border: #D9D9D9;
$color-border-dark: #CCCCCC;
$color-border-disabled: #B3B3B3;
$color-border-inverse: #FFFFFF;
$color-border-active: $color-primary;

$color-success: #2BA125;
$color-success-dark: #269936;
$color-success-light: #33CC47;
$color-warning: #E6A117;
$color-warning-dark: #CC8F14;
$color-warning-light: #FFB319;
$color-danger: #E61E2E;
$color-danger-dark: #CC1B29;
$color-danger-light: #FF2133;

$color-neutral: #808080;

$color-purple: #4E25A1;

$color-background-reg-input: #f2de3f;
$color-border-reg-input: #f2de3f;

$space-xxs: 4px;
$space-xs: 8px;
$space-sm: 12px;
$space-md: 16px;
$space-lg: 24px;
$space-xl: 32px;
$space-xxl: 40px;

$font-size-xs: 8px;
$font-size-sm: 10px;
$font-size-md: 12px;
$font-size-lg: 14px;
$font-size-xl: 16px;
$font-size-xxl: 18px;
$font-size-xxxl: 20px;

$border-radius-base: 3px;

$box-shadow-lighter: 0 3px 7px rgba(0,0,0, .1);
$box-shadow-light: 0 5px 15px rgba(0,0,0, .15);
$box-shadow-dark: 0 15px 25px rgba(0,0,0, .25);

$table-cell-height: 48px;

$grid-columns: 12;

$checkbox-size: 20px;