.dashboard {
    @include flex(row, flex-start, space-between);

    &__left {
        width: 300px;
        margin-right: $space-lg;
    }

    &__right {
        width: calc(100% - 300px);
    }
}