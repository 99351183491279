.cart-product-body-body {
    margin:10px 0px;
}

.cart-product-image , .cart-brand-image {
    width: 100px;
}

.cart-product-image__img , 
.cart-brand-image__img{
    width: 80%;
    padding:0 10px;
    background: white;
}

.custom-table-image {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.cart-product-body {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    width: 100%;
    height: 80%;
}

.remove-item {
    position: absolute;
    right: -10px;
    cursor: pointer;
    font-size: 0.9rem;
    color: #fff;
    width: 40px;
    height: 85px;
}


.remove-item:hover .remove-polygon-wings {
    background-color: #7D2524;
}

.remove-item:hover .remove-poligon-wrappper {
    /* border-color: rgb(200, 39, 41);
    background-image: -moz-linear-gradient( 90deg, rgb(179,0,3) 0%, rgb(255,79,82) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(179,0,3) 0%, rgb(255,79,82) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(179,0,3) 0%, rgb(255,79,82) 100%); */
    border-color: rgb(200, 39, 41);
    background-image: linear-gradient( 90deg, rgb(179,0,3) 0%, rgb(255,79,82) 100%);
    /* box-shadow: 0px 2px 3px 0px rgba(89, 2, 5, 0.25),inset 0px 2px 2px 0px rgba(255, 255, 255, 0.5); */
}

.remove-poligon-wrappper > i{
    position: absolute;
    z-index: 9999;
}

.remove-poligon-wrappper {    
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 70%);
    clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 70%);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(133, 144, 166);
    background-image: linear-gradient( 90deg, rgb(82,89,102) 0%, rgb(122,133,153) 100%);
    /* box-shadow: 0px 2px 3px 0px rgba(102, 111, 128, 0.25),inset 0px 2px 2px 0px rgba(255, 255, 255, 0.5); */
}

.remove-polygon-wings {
    position: absolute;
    height: 100%;
    width: 10px;
    top: 0px;
    right: 0px;
    opacity: 1;
    /* border-style: solid; */
    /* border-width: 1px; */
    background-color: rgb(82,89,102);
    box-shadow: 0px 2px 3px 0px rgba(89, 2, 5, 0.25),inset 0px 2px 2px 0px rgba(255, 255, 255, 0.5);
}

.remove-item > span{
    padding-left: 10px;
}

.cart-product-body-total-price{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart-product-body-footer-quantity__quantity input {
    width: 40px;
    height: 30px;
    outline: none;
    color: #fff;
    background: rgba(255,255,255,0.1);
    text-align:center;
    border: 1px;
}
.light .cart-product-body-footer-quantity__quantity input{
    color:#65717D;
    font-weight: bold;
    font-size: 0.7rem; 
    -moz-appearance: textfield;
}
.cart-product-body-footer-quantity__subract, .cart-product-body-footer-quantity__quantity, .cart-product-body-footer-quantity__add {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(58, 136, 25);
    /* padding: 0 7px; */
    border-bottom-right-radius: 0;
    cursor: pointer;
    border-top-right-radius: 0;
    -moz-appearance: textfield;
}

.light .cart-product-body-footer-quantity__subract, .light .cart-product-body-footer-quantity__add, .light .cart-product-body .cart-product-body-footer-quantity__quantity{
    background-image: none;
    background-color: #fff;
    /* border:0; */
    margin: 1px;
}

.light .cart-product-body-footer-quantity__quantity{
    border: 1px solid #4175a6;
    background: #fff;
}


.cart-product-body-footer-quantity__add,.cart-product-body-footer-quantity__subract{
    width: 25px;
    height: 25px;
    margin: 0 4px;
    text-align:center;
    font-size: 0.9rem;
    border: none;
}
.light .cart-product-body-footer-quantity__add, .light .cart-product-body-footer-quantity__subract{
    background: #283555;
    color: #fff; 
}

.cart-product-body-footer {
    display: flex;
    justify-content: space-between;
}
  
  .cart-product-body-footer-quantity {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: flex-start; */
  }
  
  .cart-product-body-footer-quantity > div {
      display: inline-block;
      vertical-align: middle;
  }
  
  .cart-product-body-header {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
  }