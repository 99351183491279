ul.ftp-credentials {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.ftp-credentials li {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}

ul.ftp-credentials li span:first-child {
    font-weight: bold;
}