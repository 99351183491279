.fullInfo {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
}

.fullInfo .nav-tabs {
    display: flex;
    justify-content: end;
    border: 0px;
}

.fullInfo .nav-tabs .nav-link {
    border-radius: 0px;
    text-transform: uppercase;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: bold;
    color: #ffffff;
    margin-right: 2px;
}

.light .fullInfo .nav-tabs .nav-link {
    height: 39px;
    line-height: 39px;
    padding-top: 0;
    background: #283555;
    color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
}

.light .fullInfo .nav-tabs {
    border-bottom: 0px solid #D9D9D9;
}

.fullInfo .nav-tabs .nav-link:first-child {
    border-left: 1px solid #4d4d4d;
}

.light .fullInfo .nav-tabs .nav-link:first-child {
    border-left: 1px solid #D9D9D9;

}

.fullInfo .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #125186;
    border: 0px;
}

.light .fullInfo .nav-tabs .nav-link.active, .light .fullInfo .nav-tabs .nav-link:hover {
    background: #4b87bf;
    color: #FFF;
}

.fullInfo .tab-content {
    margin: 0;
    padding: 0;
    width: 100%;
}

.light .fullInfo .tab-content {
    border: 1px solid #d9d9d9;
}

.fullInfo .tab-pane {
    background: #fff;
    min-height: 235px;
    position: relative;
}

.fullInfo .nav-item {
    border-style: solid;
    border-color: white;
    border-width: 1px;
}

.data {
    padding: 20px;
    margin: 0 !important;
}

.data .propertyWrapper {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 0.95rem;
    border-left: 5px solid #125186;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.05);
}

.light .data .propertyWrapper {
    background: #F2F2F2;
    border-left: 5px solid #3b8bea;

}

.data .propertyWrapper:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
}

.light .data .propertyWrapper:nth-child(even) {
    background: #F2F2F2;
}

.fitment .card {
    border: 0;
    border-left: 5px solid #125186;
    margin-bottom: 15px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0px;
    max-height: 350px;
}

.light .fitment .card {
    border-left: 5px solid #3b8bea;

}

.cr {
    background: rgba(255, 255, 255, 0.05);
    overflow-y: scroll;
    border-left: 0px;
    border-radius: 0px;
}

.cr .reference {
    display: flex;
    margin: 15px 0px;
    justify-content: space-between;
    font-size: 0.95rem;
    font-weight: 600;
    border-left-style: solid;
    border-left-color: #0064b7;
    border-left-width: 5px;
    padding: 10px 20px;
    border-radius: 0px;
    background-color: #eeeeee;
    margin-right: 20px;
}

.light .cr .reference {
    border-left-color: #3b8bea;
    background-color: #F2F2F2;
    border-bottom: 0 !important;
}

.cr .reference:first-child {
    margin-top: 0;
}

.fitment .btn.btn-link {
    color: #fff;
    font-weight: 600;
    padding: 0px;
    line-height: 0px;
    text-decoration: none;
}

.light .btn.btn-link {
    color: #333;
}

.fitment {
    padding: 20px;
}

.fitment .collapse {
    max-height: 350px;
    overflow-y: scroll;
    margin-bottom: 8px;
}

.fitment .vehiclesList .tr span {
    width: 100%;
    padding: 10px 0px;
}

.fitment .vehiclesList .thead {
    background: white;
    border-top: 4px solid #0066b8;
    text-transform: uppercase;
    padding: 0px 5px;
    font-weight: bold;
    min-height: 50px;
    padding: 15px;
    font-weight: bold;
    /* text-align: center; */
}

.fitment .vehiclesList .tbody {
    padding: 0px 5px;
}

.fitment .vehiclesList {
    border: 1px solid #808080;
}

.fitment .card .card-header {
    padding: 10px 10px 10px 20px;

}

.tbody.tr span {
    border: 1px solid #808080;
}

.card-header {
    border: 0px;
}

.full-info-close i {
    color: rgba(255, 255, 255, 0.5);;
}

.light .full-info-close i {
    color: #EB3B3B;
}

.main-loading {
    background-image: url("https://nika.myavail.info/public/images/vehicleLoading.gif");
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    pointer-events: none;
    overflow-y: hidden;
}

.forLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.full-info-wrap {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.full-info-wrap .fi-stock-wrap {
    width: 69%;
}

.full-info-wrap .fi-stock-wrap .table {
    max-height: 222px;
    overflow-y: auto;
}

.full-info-wrap .fi-images-wrap {
    width: 29%;
}

.fullInfo .images .slick-dots {
    bottom: 0px;
}

/* .fullInfo .images {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
} */

.fullInfo .images .image {
    /* width: 230px; */
    max-height: 195px;
    margin: 0px auto;
}

.fullInfo .images .slick-dots li {
    margin: 0px;
}

.fullInfo .images .slick-prev {
    left: 10px;
}

.fullInfo .images .slick-next {
    right: 10px;
}