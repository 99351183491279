.notification-icon{
    font-size: 25px;
    margin: 0px 10px;
    color: #4276a5;
}
.notification-icon .notifications-length {
    position: absolute;
    font-size: 0.7rem;
    right: -13px;
    top: -13px;
    width: 17px;
    height: 17px;
    color: #fff;
    background:#EB3B3D;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    line-height: 17px;
}