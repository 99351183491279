.sampleComponentsBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sampleComponentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27px;
}

.sampleComponentWrapper a{
    font-size: 16px;
}

.AccountDetails {
    width: 100%;
}

.AlertAddresses {
    width: 40%;
}

.CustomButton {
    width: 10%;
    height: 70px;
}