.compare {

    &__header {
        background-color: $color-background-inverse;
        @include flex(row, center, space-between);
        height: 48px;
        padding: 0 0 0 $space-md;
        border-radius: $border-radius-base $border-radius-base 0 0;
    }

    &__heading {
        font-size: $font-size-xxl;
        color: $color-text-inverse;
        font-weight: 700;
    }

    &__clear {
        color: transparentize($color: $color-text-inverse, $amount: .25);
        padding: 0 $space-md;
        font-size: $font-size-lg;
        @include flex(row, center, center);
        cursor: pointer;
        height: 100%;
        border-radius: 0 $border-radius-base 0 0;

        &:hover {
            background-color: $color-danger-light;
            color: transparentize($color: $color-text-inverse, $amount: 0);
        }

        i {
            margin-right: $space-xxs;
        }
    }

    &__stripes {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    &__body {
        @include flex(row, flex-start, flex-start);
        border: 1px solid $color-border;
        border-top: none;
        border-radius: 0 0 $border-radius-base $border-radius-base;
        position: relative;
    }

    &__item {
        width: 12.5%;
        background-color: $color-background;
        border-right: 1px solid $color-border;

        &--empty {
            background: none;
            position: absolute;
            top: 0;

            &:nth-child(1) {
                left: 0;
            }

            &:nth-child(2) {
                left: 12.5%;
            }

            &:nth-child(3) {
                left: 25%;
            }

            &:nth-child(4) {
                left: 37.5%;
            }

            &:nth-child(5) {
                left: 50%;
            }

            &:nth-child(6) {
                left: 62.5%;
            }

            &:nth-child(7) {
                left: 75%;
            }

            &:nth-child(8) {
                left: 87.5%;
                border-right: none;
            }
        }

        &--main {

            .compare__item-row {
                font-weight: 700;

                &:first-child {
                    font-size: $font-size-xxl;
                    text-align: center;
                    word-break: break-all;
                }
            }
        }
    }

    &__item-row {
        height: 33px;
        position: relative;
        border-top: 1px solid $color-border;
        padding: $space-md;
        @include flex(row, center, flex-start);

        &--brand {

            img {
                max-height: 22px;
            }
        }

        &--notes {
            height: 132px;
            word-break: break-word;
        }

        &:first-child {
            height: 258px;
            @include flex(column, center, space-evenly);

            .slick-slider {
                width: 100%;
            }

            .slick-slide {

                .image {
                    max-width: 100%;
                    max-height: 88px;
                    margin: 0 auto;
                    cursor: zoom-in;
                }
            }
        }
    }

    &__slider {
        width: 87.5%;

        .slick-track {
            margin: initial;

            .slick-slide:nth-child(7) {

                .compare__item {
                    border-right: none;
                }
            }
        }

        .compare__item {
            width: 100%;
        }
    }

    &__remove {
        position: absolute;
        right: $space-xs;
        top: $space-xs;
        cursor: pointer;
        color: transparentize($color: $color-text, $amount: .5);

        &:hover {
            color: transparentize($color: $color-text, $amount: 0);
        }
    }
}