.custom-wrapper {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0px 0px 5px #e6e6e6;
    width: 100%;
    height: 100%;
}

.custom-heading{
        font-weight: 600;
    color: #4d4f5c;
    font-weight: bold;
    font-size: 1rem;
    display: block;
}

.custom-filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 30px;
}

.custom-filter .filter-group {
    margin-right: 1%;
    width: 15%;
}

.custom-filter .filter-group select,
.custom-filter .filter-group input {
    width: 100%;
    height: 30px;
    background: #fff;
    border: none;
    border:1px solid #D9D9D9;
    padding-left: 10px;
    padding-right: 17px;
    outline: 0px;
    border-radius: 0px!important;
    background-image: none!important;
        font-weight: 600;
}

.custom-filter .filter-group label {
    color: #4d4f5c;
    font-weight: bold;
    font-size: 0.7rem;
    display: block;
}

.custom-table {
    width: 100%;
    margin: 0px;
}

.custom-table thead th {
    background: #273655;
    color: #fff;
    font-weight: bold;
    border:0px!important;
}

.custom-table tbody td {
    background: #FFF;
    color: #4D4F5C;
    font-weight: 600;
    vertical-align: middle;
    height: 60px;
}

.custom-table td,
.custom-table th {
        font-weight: 600;
    font-size: .7rem;
    font-weight: normal;
    text-align: center;
    position: relative;
}

.custom-btn {
    background: #4276A6;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 7px 12px;
    cursor: pointer;
    position: relative;
    outline: none;
}

.custom-btn.btn-green:hover{
    text-decoration: none;
    color: #fff;
}

.react-datepicker__input-container {
    width: 100%;
}
.react-datepicker-wrapper {
    width: 100%;
}

.copy-pn {
    border: none;
    outline: none;
    cursor: pointer;
}

.cart-product_img{
    max-height: 50px;
}