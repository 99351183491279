.mt-4 {
    margin-top: $space-xxs;
}

.mt-8 {
    margin-top: $space-xs;
}

.mt-12 {
    margin-top: $space-sm;
}

.mt-16 {
    margin-top: $space-md;
}

.mt-24 {
    margin-top: $space-lg;
}

.mt-32 {
    margin-top: $space-xl;
}


.mt-40 {
    margin-top: $space-xxl;
}

.ml-4 {
    margin-left: $space-xxs;
}

.ml-8 {
    margin-left: $space-xs;
}

.ml-12 {
    margin-left: $space-sm;
}

.ml-16 {
    margin-left: $space-md;
}

.ml-24 {
    margin-left: $space-lg;
}

.ml-32 {
    margin-left: $space-xl;
}


.ml-40 {
    margin-left: $space-xxl;
}

.mb-4 {
    margin-bottom: $space-xxs;
}

.mb-8 {
    margin-bottom: $space-xs;
}

.mb-12 {
    margin-bottom: $space-sm;
}

.mb-16 {
    margin-bottom: $space-md;
}

.mb-24 {
    margin-bottom: $space-lg;
}

.mb-32 {
    margin-bottom: $space-xl;
}

.mb-40 {
    margin-bottom: $space-xxl;
}

.mr-4 {
    margin-right: $space-xxs;
}

.mr-8 {
    margin-right: $space-xs;
}

.mr-12 {
    margin-right: $space-sm;
}

.mr-16 {
    margin-right: $space-md;
}

.mr-24 {
    margin-right: $space-lg;
}

.mr-32 {
    margin-right: $space-xl;
}

.mr-40 {
    margin-right: $space-xxl;
}

.mr-auto {
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mh-270 {
    max-height: 270px;
    overflow-y: auto;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-align-center {
    align-items: center;
}

.flex-justify-start {
    justify-content: flex-start;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-justify-center {
    justify-content: center;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700 ;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.fs-12 {
    font-size: 12px;
}

.fs-16 {
    font-size: 16px;
}

.fs-24 {
    font-size: 24px;
}

.text-primary {
    color: $color-primary;
}

.text-danger {
    color: $color-danger-light;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-underline {

    &:hover {
        text-decoration: underline;
    }
}