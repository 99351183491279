.nav {
    height: 100%;

    &__list {
        height: 100%;
        @include flex(row, center, flex-start);
    }

    &__item {
        height: 100%;
        border-left: 1px solid $color-border;
        position: relative;

        &--account {

            .dropdown-menu {
                display: none;
                border: 1px solid transparentize($color: #000, $amount: .85);
                box-shadow: $box-shadow-light;

                &.show {
                    display: block;
                    min-width: 220px;
                    left: 0!important;
                    top: calc(100% + 16px)!important;
                    transform: none!important;
                }
            }

            .nav__icon {

                .badge {
                    right: -20px;
                }
            }
        }

        &.show {
            
            .nav__link {
                background-color: $color-background-dark;
                border-top: 3px solid $color-primary-light;
            }

            .nav__icon {
                color: $color-primary-light;
            }

            .nav__text {
                color: $color-primary-light;
            }
        }

        &:last-child {
            border-right: 1px solid $color-border;
        }
    }

    &__link {
        height: 100%;
        @include flex(row, center, center);
        padding: 0 $space-lg;
        border-top: 3px solid transparent;

        &:hover {
            color: $color-text-active;
            
            .nav__icon {
                
                .icon {
                    background-color: $color-icon-active;
                }
            }

            .nav__text {
                color: $color-text-active;
            }
        }

        &--active {
            background-color: $color-background-dark;
            color: $color-icon-active;
            border-top: 3px solid $color-primary;

            .nav__icon {
                
                .icon {
                    background-color: $color-icon-active;
                }
            }

            .nav__text {
                color: $color-text-active;
            }
        }
    }

    &__text {
        color: $color-text;
        text-transform: uppercase;
        font-weight: bold;
        font-size: $font-size-md;
    }

    &__icon {
        font-size: 25px;
        margin-right: $space-md;
        color: $color-icon;
        position: relative;

        .badge {
            position: absolute;
            top: -$space-md;
            right: -$space-md;
        }
    }
}