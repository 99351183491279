.icon {
    display: block;

    &--dark {
        background-color: $color-icon;
    }

    &--light {
        background-color: $color-icon-inverse;
    }

    &--active {
        background-color: $color-icon-active;
    }
}