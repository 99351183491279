.call-btn {
    width: $space-xxl;
    height: $space-xxl;
    border-radius: $border-radius-base;
    @include flex(row, center, center);
    color: $color-icon-inverse;
    background-color: $color-warning;
    cursor: pointer;

    &:hover {
        background-color: $color-warning-light;
    }
}