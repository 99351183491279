.compare-icon {
    /* display: block; */
    position: relative;
    color: #fff;
    font-size: 25px;
    margin: 0px 10px;
}

.light .compare-icon {
    position: relative;
    font-size: 25px;
    color: #333;
}

.light .home .compare-icon {
    color: #fff;
}

/* .light .compare-icon:hover {
    color: #4276a5;
} */

.compare-icon .compare-items-length {
    position: absolute;
    font-size: 10px;
    top: -16px;
    color: #fff!important;
    background: #eb3b3b;
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    border: 2px solid #fff;;
    align-items: center;
    justify-content: center;
    font-family: Arial;
    padding: 4px 8px;
    right: auto;
    margin-left: 10px;
}

.light .compare-icon .compare-items-length {
    background: #eb3b3b;
}

.topIconsLinks:hover .compare-items-length {
    color: #fff;
}

.topIconsLinks.active .compare-items-length {
    color: #fff;
}
