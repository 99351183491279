/* @import "../admin/index.css"; */
@media screen {
    .div-to-print .table-to-print {
        display: none;
    }
}

@media print {
    .no-print {
        display: none;
    }
    .div-to-print {
        width: 100vw;
        height: 100vh;
        page-break-after: always;
    }
    .div-to-print > div {
        width: 760px;
        margin: 0 auto;
    }
    .div-to-print .table-to-print {
        display: block;
    }
    .div-to-print .table-not-to-print {
        display: none; 
    }
    .div-to-print .no-print {
        display: none;
    }
    .div-to-print .table__th {
        border-bottom: 1px solid #D9D9D9;
    }
    .div-to-print .table__td{
        font-size: 10px;
    }
    .div-to-print thead{
        position: relative;
        z-index: 0;
    }
    .div-to-print tbody{
        position: relative;
        z-index: 999;
    }
}